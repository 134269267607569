<template>
	<div class="main">
		<div class="intro intro_start">
			<div class="intro__inner">
				<img
					alt="Vue logo"
					src="img/logo-light-large.svg"
					width="327"
				/>

				<a href="/login" class="btn btn-round ">
					Los geht’s!
				</a>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'Intro',
	components: {}
};
</script>
