<template>
	<div
		class="home main main--with-bg"
		style="background-image: url(/img/bg-1.svg); background-size: cover"
	>
		<HintEye
			msg="Tausche Dich mit Deinen Kollegen zu den Themen und Fragen Eurer Selbstentwicklung aus."
		/>

		<div class="shell shell--medium d-block d-md-flex  align-items-center">
			<Loader v-show="this.isLoading" />

			<div v-show="!this.isLoading" class="steps steps--alt">
				<VueSlickCarousel v-bind="slider_settings">
					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--alt step--1">
							<a
								href="/community/community-dein-einstieg"
								class="step__inner"
							>
								<img src="/img/step-alt-1.svg" alt="" />

								<svg
									width="186"
									height="186"
									xmlns="http://www.w3.org/2000/svg"
									class="line-absolute"
								>
									<path
										d="M185.579 118.939c-5.919 21.085-20.092 40.174-41.362 52.6-44.178 25.786-102.2 13.026-129.636-28.51C-12.855 101.493 1.985 28.217 36.332 8.156c34.347-20.061 110.814-.061 138.2 41.475a86.3 86.3 0 019.217 18.29"
										stroke="#44737D"
										stroke-width=".6"
										fill="none"
										fill-rule="evenodd"
										opacity=".4"
										stroke-linecap="round"
										stroke-linejoin="round"
										class="line line1"
									/>
								</svg>

								<span class="text">Dein Einstieg</span>
							</a>
						</div>
					</div>
					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--alt step--2">
							<a
								href=""
								class="step__inner"
								@click.prevent="
									showNotification($event, -110, 20)
								"
							>
								<img src="/img/step-alt-2.svg" alt="" />

								<svg
									class="line-absolute"
									width="209"
									height="152"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										class="line line2"
										d="M207.8 106.9c-6.1 18.6-17.8 26.1-30.7 29.9-13 3.9-39.3-3.4-51-2.3-19.8 1.8-37.9 18.2-66.2 16.3-43.2-2.9-62.3-44.2-59-70.6C12.7-13.2 169.7-26.2 203.2 50.1"
										stroke="#44737D"
										stroke-width=".6"
										opacity=".4"
										fill="none"
										fill-rule="evenodd"
										stroke-linecap="round"
									/>
								</svg>
								<span class="text"
									>Wahrnehmung & Achtsamkeit</span
								>
							</a>
						</div>
					</div>
					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--alt step--3">
							<a
								href=""
								class="step__inner"
								@click.prevent="
									showNotification($event, -110, 20)
								"
							>
								<img src="/img/step-alt-3.svg" alt="" />
								<svg
									class=" line-absolute"
									width="208"
									height="185"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M207.1 109.6s.7 25.9-24.4 49.8c-22.6 21.6-65.4 38.8-125.2 7.7-64.5-33.5-67.9-99.4-39-135.6 9.4-11.8 39.4-42.3 72-25.9 14.9 7.5 22.6 25.3 40.1 35.1 14.2 8 44.3 6.6 65.7 33.3"
										stroke="#44737d"
										stroke-width=".6"
										fill="none"
										fill-rule="evenodd"
										stroke-linecap="round"
										opacity=".4"
										class="line line3"
									/>
								</svg>
								<span class="text">Bewusstsein</span>
							</a>
						</div>
					</div>
					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--alt step--4">
							<a
								href=""
								class="step__inner"
								@click.prevent="
									showNotification($event, -110, 20)
								"
							>
								<img src="/img/step-alt-4.svg" alt="" />
								<svg
									class=" line-absolute"
									xmlns="http://www.w3.org/2000/svg"
									width="190.847"
									height="177.082"
								>
									<g data-name="Group 4755">
										<path
											data-name="Path 10973"
											d="M190.255 104.508a62.468 62.468 0 01-.6 16.539 52.284 52.284 0 01-1.224 5.223c-6.032 20.819-23.769 38.484-45.94 45.755C80.931 192.219 2.062 144.856.321 84.129c-.717-24.885 16.856-47.291 37.44-63.676 9.524-7.547 20.092-14.337 32.145-17.737 20.983-5.878 44.444-.451 62.468 11.039s35.945 32.166 47.107 54.736"
											fill="none"
											stroke="#44737d"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width=".6"
											opacity=".4"
											class="line line4"
										/>
									</g>
								</svg>
								<span class="text">Selbstreflektion</span>
							</a>
						</div>
					</div>
					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--alt step--5">
							<a
								href=""
								class="step__inner"
								@click.prevent="
									showNotification($event, -110, 20)
								"
							>
								<img src="/img/step-alt-5.svg" alt="" />
								<svg
									class=" line-absolute"
									width="180"
									height="166"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M170.8 105.8c-18.6 35.3-121.9 109.1-163 7.2C-4 83.8-6.2 49.7 36.5 19.3 78.4-10.5 159.9-7.1 179.1 52.5"
										stroke="#44737d"
										stroke-width=".6"
										fill="none"
										fill-rule="evenodd"
										stroke-linecap="round"
										opacity=".4"
										class="line line6"
									/>
								</svg>
								<span class="text">Interaktion & Teamwork</span>
							</a>
						</div>
					</div>
					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--alt step--6">
							<a
								href=""
								class="step__inner"
								@click.prevent="
									showNotification($event, -110, 20)
								"
							>
								<img src="/img/step-alt-6.svg" alt="" />
								<svg
									class=" line-absolute"
									xmlns="http://www.w3.org/2000/svg"
									width="182.944"
									height="167.523"
								>
									<g data-name="Group 4758">
										<path
											data-name="Path 10986"
											d="M182.57 52.831a67 67 0 00-21.214-32.125C114.674-17.941 28.318.795 4.768 56.806c-9.648 22.958-2.366 49.568 9.771 71.333 5.61 10.107 12.412 19.848 21.755 26.875 16.319 12.239 38.828 14.728 58.778 9.822 27.538-6.793 61.756-30.139 79.074-57.625"
											fill="none"
											stroke="#44737d"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width=".6"
											opacity=".4"
											class="line line6"
										/>
									</g>
								</svg>
								<span class="text"
									>Zielsetzung & Fokussierung</span
								>
							</a>
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</div>

		<NotificationBox
			:event="this.event"
			:showNotification="this.isNotificationVisible"
			:offsetLeft="this.offsetLeft"
			:offsetTop="this.offsetTop"
			@onClose="this.onNotificationClose"
		/>
	</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import HintEye from '@/components/HintEye.vue';
import NotificationBox from '@/components/NotificationBox.vue';
import Loader from '@/components/Loader.vue';

export default {
	name: 'Home',
	components: {
		HintEye,
		VueSlickCarousel,
		NotificationBox,
		Loader
	},
	data() {
		return {
			isLoading: false,
			isNotificationVisible: false,
			offsetLeft: 0,
			offsetTop: 0,
			event: {},
			slider_settings: {
				dots: true,
				arrows: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 0,
				responsive: [
					{
						breakpoint: 9999,
						settings: 'unslick'
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: false,
							dots: true
						}
					}
				]
			}
		};
	},
	methods: {
		showNotification(event, x, y) {
			this.isNotificationVisible = true;
			this.event = event;
			this.offsetLeft = x;
			this.offsetTop = y;
		},
		onNotificationClose() {
			this.isNotificationVisible = false;
		},
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2700);
		}
	},
	mounted: function() {
		this.loading();
		var path = document.querySelector('.line');
		var length = path.getTotalLength();

		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
