<template>
	<div
		class="home main main--with-bg main-multiply"
		style="background-image: url(/img/bg-1.svg); background-size: cover"
	>
		<div class="shell ">
			<div class="page-top-bar">
				<div class="breadcrumbs">
					<ul>
						<li>
							<a href="/home">Übersicht > </a>
						</li>

						<li>
							<a href="/community">Community ></a>
						</li>

						<li>
							<span>Dein Einstieg</span>
						</li>
					</ul>
				</div>

				<div
					class="search"
					@click.prevent="showNotification($event, -110, 20)"
				>
					<div class="form-search">
						<form action="" @submit="" method="post">
							<div class="form__body">
								<div class="form__controls">
									<input
										type="text"
										class="search_field "
										value=""
										id="field-search"
										placeholder="Suchen"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col col-12 col-lg-10">
					<div
						class="page_title page_title--underlined page_title--network text-center"
					>
						<h4>
							<span class="w-100 text-center"
								>Deine Community rund um das Modul 1
								<br v-if="$mq === 'mobile'" />
								<strong>“DEIN EINSTIEG”</strong></span
							>
						</h4>
					</div>

					<div class="table-accordion">
						<div class="table-head">
							<div class="cell-size1">Thema</div>
							<div class="cell-size2">Unterhaltungen</div>
							<div class="cell-size3">Letzte Änderung</div>
						</div>

						<div class="table-body">
							<div class="table-row">
								<div
									:class="[
										'table-accordion-toggle',
										{
											'table-accordion-toggle--open': this
												.isAccordionExpanded
										}
									]"
									@click="expandAccordion"
								>
									<strong class="size1"
										>Mental stark bleiben</strong
									>

									<strong
										v-if="$mq !== 'mobile'"
										class="size2"
										>22</strong
									>

									<strong
										v-if="$mq !== 'mobile'"
										class="size3"
										>Vor 2 Stunden</strong
									>

									<i class="arrow">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="17.121"
											height="9.31"
											viewBox="0 0 17.121 9.31"
										>
											<path
												id="Path_11614"
												data-name="Path 11614"
												d="M7609.614,1069l7.5,7.5,7.5-7.5"
												transform="translate(-7608.554 -1067.939)"
												fill="none"
												stroke="#fff"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="1.5"
											/>
										</svg>
									</i>
								</div>

								<div class="collapsed-content">
									<VueSlideToggle
										:open="this.isAccordionExpanded"
										tag="section"
										:duration="500"
									>
										<ul class="list-features-icons">
											<li
												@click.prevent="
													showNotification(
														$event,
														-110,
														20
													)
												"
											>
												<i>
													<img
														src="/img/list-feature-icon1.svg"
														alt=""
													/>
												</i>

												<span>Alle Beiträge</span>
											</li>

											<li
												@click.prevent="
													showNotification(
														$event,
														-110,
														20
													)
												"
											>
												<i>
													<img
														src="/img/list-feature-icon2.svg"
														alt=""
													/>
												</i>

												<span>Alle Voice Messages</span>
											</li>

											<li
												@click.prevent="
													showNotification(
														$event,
														-110,
														20
													)
												"
											>
												<i>
													<img
														src="/img/list-feature-icon3.svg"
														alt=""
													/>
												</i>

												<span>Alle Podcasts</span>
											</li>
										</ul>
									</VueSlideToggle>
								</div>
							</div>

							<div class="table-row">
								<div
									:class="[
										'table-accordion-toggle',
										{
											'table-accordion-toggle--open': false
										}
									]"
									@click.prevent="
										showNotification($event, -110, 20)
									"
								>
									<strong class="size1"
										>Glaubensmuster entdecken?</strong
									>

									<strong
										v-if="$mq !== 'mobile'"
										class="size2"
										>12</strong
									>

									<strong
										v-if="$mq !== 'mobile'"
										class="size3"
										>Vor 2 Stunden</strong
									>

									<i class="arrow">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="17.121"
											height="9.31"
											viewBox="0 0 17.121 9.31"
										>
											<path
												id="Path_11614"
												data-name="Path 11614"
												d="M7609.614,1069l7.5,7.5,7.5-7.5"
												transform="translate(-7608.554 -1067.939)"
												fill="none"
												stroke="#fff"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="1.5"
											/>
										</svg>
									</i>
								</div>
							</div>

							<div class="table-row">
								<div
									:class="[
										'table-accordion-toggle',
										{
											'table-accordion-toggle--open': false
										}
									]"
									@click.prevent="
										showNotification($event, -110, 20)
									"
								>
									<strong class="size1"
										>Übungen gegen stress</strong
									>

									<strong
										v-if="$mq !== 'mobile'"
										class="size2"
										>12</strong
									>

									<strong
										v-if="$mq !== 'mobile'"
										class="size3"
										>Vor 2 Stunden</strong
									>

									<i class="arrow">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="17.121"
											height="9.31"
											viewBox="0 0 17.121 9.31"
										>
											<path
												id="Path_11614"
												data-name="Path 11614"
												d="M7609.614,1069l7.5,7.5,7.5-7.5"
												transform="translate(-7608.554 -1067.939)"
												fill="none"
												stroke="#fff"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="1.5"
											/>
										</svg>
									</i>
								</div>
							</div>
						</div>

						<div class="table-actions">
							<a
								href=""
								class="btn-square"
								@click.prevent="
									showNotification($event, -110, 20)
								"
								>+ Neues Thema hinzufügen</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<NotificationBox
			:event="this.event"
			:showNotification="this.isNotificationVisible"
			:offsetLeft="this.offsetLeft"
			:offsetTop="this.offsetTop"
			@onClose="this.onNotificationClose"
		/>
	</div>
</template>

<script>
// @ is an alias to /src

import NotificationBox from '@/components/NotificationBox.vue';

export default {
	name: 'NetworkInner',
	components: {
		NotificationBox
	},
	data() {
		return {
			isNotificationVisible: false,
			offsetLeft: 0,
			offsetTop: 0,
			event: {},
			isAccordionExpanded: false
		};
	},
	methods: {
		isActive(menuItem) {
			return this.activeItem === menuItem;
		},
		setActive(menuItem) {
			this.activeItem = menuItem;
		},
		expandAccordion() {
			this.isAccordionExpanded = !this.isAccordionExpanded;
		},
		showNotification(event, x, y) {
			this.isNotificationVisible = true;
			this.event = event;
			this.offsetLeft = x;
			this.offsetTop = y;
		},
		onNotificationClose() {
			this.isNotificationVisible = false;
		}
	},
	mounted: function() {
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
