<template>
	<div
		class="home main main--with-bg main--levels"
		style="background-image: url(/img/bg-2.svg); background-size: cover"
	>
		<div class="shell">
			<div class="levels-holder">
				<div class="pillow pillow-1">
					<img
						src="/img/pillow-white-1.svg"
						alt=""
						width="344"
						height="234"
					/>

					<span class="text">dein einstieg</span>
				</div>

				<div class="levels">
					<div class="level">
						<div class="level__toggle">
							<i>
								<svg
									id="Group_4690"
									data-name="Group 4690"
									xmlns="http://www.w3.org/2000/svg"
									width="13.661"
									height="19.427"
									viewBox="0 0 13.661 19.427"
								>
									<path
										id="Path_10633"
										data-name="Path 10633"
										d="M5700.249-1248.641v3.282h-1.536v-3.282a3.227,3.227,0,0,0-3.219-3.223,3.228,3.228,0,0,0-3.223,3.223v3.282h-1.536v-3.282a4.764,4.764,0,0,1,4.759-4.759A4.762,4.762,0,0,1,5700.249-1248.641Z"
										transform="translate(-5688.661 1253.4)"
										fill="#004850"
									/>
									<path
										id="Path_10634"
										data-name="Path 10634"
										d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
										transform="translate(-5683.755 1234.371)"
										fill="#004850"
									/>
								</svg>
							</i>

							<span>Basis Umfrage</span>

							<i class="arrow">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="17.121"
									height="9.31"
									viewBox="0 0 17.121 9.31"
								>
									<path
										id="Path_11614"
										data-name="Path 11614"
										d="M7609.614,1069l7.5,7.5,7.5-7.5"
										transform="translate(-7608.554 -1067.939)"
										fill="none"
										stroke="#004850"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1.5"
									/>
								</svg>
							</i>
						</div>
					</div>

					<div class="level">
						<div
							:class="[
								'level__toggle level__toggle--active',
								{
									'level__toggle--open': this
										.isAccordionExpanded
								}
							]"
							@click="expandAccordion"
						>
							<i>
								<svg
									id="Group_341"
									data-name="Group 341"
									xmlns="http://www.w3.org/2000/svg"
									width="19.587"
									height="19.427"
									viewBox="0 0 19.587 19.427"
								>
									<path
										id="Path_10633"
										data-name="Path 10633"
										d="M5690.734-1248.641v3.282h1.536v-3.282a3.227,3.227,0,0,1,3.219-3.223,3.228,3.228,0,0,1,3.223,3.223v3.282h1.536v-3.282a4.764,4.764,0,0,0-4.759-4.759A4.762,4.762,0,0,0,5690.734-1248.641Z"
										transform="translate(-5680.661 1253.4)"
										fill="#004850"
									/>
									<path
										id="Path_10634"
										data-name="Path 10634"
										d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
										transform="translate(-5683.755 1234.371)"
										fill="#004850"
									/>
								</svg>
							</i>

							<span>Bedeutung mentaler Stärke</span>

							<i class="arrow">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="17.121"
									height="9.31"
									viewBox="0 0 17.121 9.31"
								>
									<path
										id="Path_11614"
										data-name="Path 11614"
										d="M7609.614,1069l7.5,7.5,7.5-7.5"
										transform="translate(-7608.554 -1067.939)"
										fill="none"
										stroke="#004850"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1.5"
									/>
								</svg>
							</i>
						</div>

						<div class="list-tasks">
							<VueSlideToggle
								:open="this.isAccordionExpanded"
								tag="section"
								:duration="500"
							>
								<ul>
									<li class="selected">
										<a href="/exercise1">
											<i>
												<svg
													id="Group_341"
													data-name="Group 341"
													xmlns="http://www.w3.org/2000/svg"
													width="19.587"
													height="19.427"
													viewBox="0 0 19.587 19.427"
												>
													<path
														id="Path_10633"
														data-name="Path 10633"
														d="M5690.734-1248.641v3.282h1.536v-3.282a3.227,3.227,0,0,1,3.219-3.223,3.228,3.228,0,0,1,3.223,3.223v3.282h1.536v-3.282a4.764,4.764,0,0,0-4.759-4.759A4.762,4.762,0,0,0,5690.734-1248.641Z"
														transform="translate(-5680.661 1253.4)"
														fill="#004850"
													/>
													<path
														id="Path_10634"
														data-name="Path 10634"
														d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
														transform="translate(-5683.755 1234.371)"
														fill="#004850"
													/>
												</svg>
											</i>

											<span
												>Intro zu mentaler Stärke</span
											>
										</a>
									</li>

									<li class="selected">
										<a href="/warm-up1"
											><i>
												<svg
													id="Group_341"
													data-name="Group 341"
													xmlns="http://www.w3.org/2000/svg"
													width="19.587"
													height="19.427"
													viewBox="0 0 19.587 19.427"
												>
													<path
														id="Path_10633"
														data-name="Path 10633"
														d="M5690.734-1248.641v3.282h1.536v-3.282a3.227,3.227,0,0,1,3.219-3.223,3.228,3.228,0,0,1,3.223,3.223v3.282h1.536v-3.282a4.764,4.764,0,0,0-4.759-4.759A4.762,4.762,0,0,0,5690.734-1248.641Z"
														transform="translate(-5680.661 1253.4)"
														fill="#004850"
													/>
													<path
														id="Path_10634"
														data-name="Path 10634"
														d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
														transform="translate(-5683.755 1234.371)"
														fill="#004850"
													/>
												</svg>
											</i>

											<span>WarmUp</span></a
										>
									</li>
								</ul>
							</VueSlideToggle>
						</div>
					</div>

					<div class="level">
						<div class="level__toggle">
							<i>
								<svg
									id="Group_4690"
									data-name="Group 4690"
									xmlns="http://www.w3.org/2000/svg"
									width="13.661"
									height="19.427"
									viewBox="0 0 13.661 19.427"
								>
									<path
										id="Path_10633"
										data-name="Path 10633"
										d="M5700.249-1248.641v3.282h-1.536v-3.282a3.227,3.227,0,0,0-3.219-3.223,3.228,3.228,0,0,0-3.223,3.223v3.282h-1.536v-3.282a4.764,4.764,0,0,1,4.759-4.759A4.762,4.762,0,0,1,5700.249-1248.641Z"
										transform="translate(-5688.661 1253.4)"
										fill="#004850"
									/>
									<path
										id="Path_10634"
										data-name="Path 10634"
										d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
										transform="translate(-5683.755 1234.371)"
										fill="#004850"
									/>
								</svg>
							</i>

							<span>Einstieg in Sibando</span>

							<i class="arrow">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="17.121"
									height="9.31"
									viewBox="0 0 17.121 9.31"
								>
									<path
										id="Path_11614"
										data-name="Path 11614"
										d="M7609.614,1069l7.5,7.5,7.5-7.5"
										transform="translate(-7608.554 -1067.939)"
										fill="none"
										stroke="#004850"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1.5"
									/>
								</svg>
							</i>
						</div>
					</div>
				</div>
			</div>

			<div class="character-fixed character-fixed--1">
				<img src="/img/character1.svg" alt="" />
			</div>

			<div class="bottom-actions bottom-actions-with-shell">
				<div class="shell shell--alt">
					<btn-back></btn-back>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BtnBack from '@/components/BtnBack.vue';
export default {
	name: 'Level1',
	components: { BtnBack },
	data() {
		return {
			isAccordionExpanded: false
		};
	},
	methods: {
		isActive(menuItem) {
			return this.activeItem === menuItem;
		},
		setActive(menuItem) {
			this.activeItem = menuItem;
		},
		expandAccordion() {
			this.isAccordionExpanded = !this.isAccordionExpanded;
		}
	},
	mounted: function() {
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
