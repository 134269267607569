<template>
    <div
        id="app"
        :class="[
            {
                'app-desktop': !this.isTouch
            }
        ]"
    >
        <Header v-if="!['Login', 'Start'].includes($route.name)" />

        <router-view />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
export default {
    name: 'App',
    components: { Header },
    data() {
        return {
            isTouch: false
        };
    },
    methods: {
        setMinHeight() {
            const body = document.querySelector('body');
            body.style.minHeight = window.innerHeight + 'px';
        }
    },
    mounted: function() {
        this.setMinHeight();

        if ('ontouchstart' in document.documentElement) {
            this.isTouch = true;
        } else {
            this.isTouch = false;
        }
    },
    created() {
        window.addEventListener('resize', this.setMinHeight);
    },
    destroyed() {
        window.removeEventListener('resize', this.setMinHeight);
    }
};
</script>

<style lang="scss">
#app {
    width: 100%;
    display: flex;
    flex-direction: column;
}
body {
    margin: 0;
    min-height: 100vh;
    display: flex;
}

.main {
    flex-grow: 1;
    display: flex;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
