<template>
	<button @click="goBack" class="btn btn--white btn--text-small">
		Zurück
	</button>
</template>

<script>
export default {
	name: 'BtnBack',

	data() {
		return {};
	},
	methods: {
		goBack() {
			return this.$router.go(-1);
		}
	}
};
</script>

<style lang="css" scoped></style>
