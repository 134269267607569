<template>
    <div class="header">
        <div class="shell">
            <div class="logo__holder">
                <router-link :to="{ name: 'Home' }" class="logo">
                    <img
                        alt="Vue logo"
                        src="/img/logo-dark-large2.svg"
                        width="138"
                    />
                </router-link>
            </div>

            <Nav :class="[{ 'nav--open': $mq === 'mobile' && isMenuOpen }]" />

            <div
                :class="['header__inner ml-auto w-full text-right']"
                v-click-outside="hideUserDropdown"
            >
                <div
                    :class="[
                        'logged_user',
                        { 'logged_user--open': showUserDropdown }
                    ]"
                >
                    <a href="" class="toggle" @click.prevent="openUserDropdown">
                        <div class="thumb">
                            <img
                                src="/img/user-thumb.svg"
                                alt=""
                                width="43"
                                height="43"
                            />
                        </div>
                    </a>

                    <div class="dropdown">
                        <a
                            href=""
                            class="btn-close"
                            @click.prevent="hideUserDropdown"
                        >
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7.914"
                                    height="7.917"
                                    viewBox="0 0 7.914 7.917"
                                >
                                    <g
                                        id="Group_4776"
                                        data-name="Group 4776"
                                        transform="translate(-863.293 -564.793)"
                                    >
                                        <path
                                            id="Path_11651"
                                            data-name="Path 11651"
                                            d="M13418.578-3034.018l6.5,6.5"
                                            transform="translate(-12554.578 3599.518)"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                        <path
                                            id="Path_11652"
                                            data-name="Path 11652"
                                            d="M0,0,6.5,6.5"
                                            transform="translate(870.498 565.503) rotate(90)"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                    </g>
                                </svg>
                            </i>
                        </a>
                        <ul>
                            <li>
                                <a href="" @click="loggout()">Ausloggen</a>
                            </li>

                            <li>
                                <a
                                    href=""
                                    @click.prevent="
                                        showNotification($event, -110, 20)
                                    "
                                    >Einstellungen</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <a
                href="#"
                v-if="$mq === 'mobile'"
                :class="['btn-menu', { 'btn-menu--active': isMenuOpen }]"
                @click="toggleMenu"
            >
                <span></span>
                <span></span>
                <span></span>
            </a>

            <NotificationBox
                :event="this.event"
                :showNotification="this.isNotificationVisible"
                :offsetLeft="this.offsetLeft"
                :offsetTop="this.offsetTop"
                @onClose="this.onNotificationClose"
            />
        </div>
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue';
import NotificationBox from '@/components/NotificationBox.vue';
export default {
    name: 'Header',
    components: { Nav, NotificationBox },
    props: {
        msg: String
    },
    data() {
        return {
            isMenuOpen: false,
            showUserDropdown: false,
            isNotificationVisible: false,
            offsetLeft: 0,
            offsetTop: 0,
            event: {}
        };
    },
    watch: {
        $route(to, from) {
            this.isMenuOpen = false;
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        hideUserDropdown() {
            this.showUserDropdown = false;

            this.isNotificationVisible = false;
        },
        openUserDropdown() {
            this.showUserDropdown = true;
            this.isMenuOpen = false;
        },

        showNotification(event, x, y) {
            this.isNotificationVisible = true;
            this.event = event;
            this.offsetLeft = x;
            this.offsetTop = y;
        },
        onNotificationClose() {
            this.isNotificationVisible = false;
        },
        loggout() {
            localStorage.removeItem('authUser');
            localStorage.removeItem('loginExpireTime');
        }
    }
};
</script>

<style scoped lang="scss"></style>
