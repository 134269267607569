var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home main main--with-bg main-multiply",staticStyle:{"background-image":"url(/img/bg-1.svg)","background-size":"cover"}},[_c('div',{staticClass:"shell "},[_c('div',{staticClass:"page-top-bar"},[_vm._m(0),_c('div',{staticClass:"search",on:{"click":function($event){$event.preventDefault();return _vm.showNotification($event, -110, 20)}}},[_c('div',{staticClass:"form-search"},[_c('form',{attrs:{"action":"","method":"post"},on:{"submit":function($event){}}},[_vm._m(1)])])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col col-12 col-lg-10"},[_c('div',{staticClass:"page_title page_title--underlined page_title--network text-center"},[_c('h4',[_c('span',{staticClass:"w-100 text-center"},[_vm._v("Deine Community rund um das Modul 1 "),(_vm.$mq === 'mobile')?_c('br'):_vm._e(),_c('strong',[_vm._v("“DEIN EINSTIEG”")])])])]),_c('div',{staticClass:"table-accordion"},[_vm._m(2),_c('div',{staticClass:"table-body"},[_c('div',{staticClass:"table-row"},[_c('div',{class:[
									'table-accordion-toggle',
									{
										'table-accordion-toggle--open': this
											.isAccordionExpanded
									}
								],on:{"click":_vm.expandAccordion}},[_c('strong',{staticClass:"size1"},[_vm._v("Mental stark bleiben")]),(_vm.$mq !== 'mobile')?_c('strong',{staticClass:"size2"},[_vm._v("22")]):_vm._e(),(_vm.$mq !== 'mobile')?_c('strong',{staticClass:"size3"},[_vm._v("Vor 2 Stunden")]):_vm._e(),_c('i',{staticClass:"arrow"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"17.121","height":"9.31","viewBox":"0 0 17.121 9.31"}},[_c('path',{attrs:{"id":"Path_11614","data-name":"Path 11614","d":"M7609.614,1069l7.5,7.5,7.5-7.5","transform":"translate(-7608.554 -1067.939)","fill":"none","stroke":"#fff","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}})])])]),_c('div',{staticClass:"collapsed-content"},[_c('VueSlideToggle',{attrs:{"open":this.isAccordionExpanded,"tag":"section","duration":500}},[_c('ul',{staticClass:"list-features-icons"},[_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.showNotification(
													$event,
													-110,
													20
												)}}},[_c('i',[_c('img',{attrs:{"src":"/img/list-feature-icon1.svg","alt":""}})]),_c('span',[_vm._v("Alle Beiträge")])]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.showNotification(
													$event,
													-110,
													20
												)}}},[_c('i',[_c('img',{attrs:{"src":"/img/list-feature-icon2.svg","alt":""}})]),_c('span',[_vm._v("Alle Voice Messages")])]),_c('li',{on:{"click":function($event){$event.preventDefault();return _vm.showNotification(
													$event,
													-110,
													20
												)}}},[_c('i',[_c('img',{attrs:{"src":"/img/list-feature-icon3.svg","alt":""}})]),_c('span',[_vm._v("Alle Podcasts")])])])])],1)]),_c('div',{staticClass:"table-row"},[_c('div',{class:[
									'table-accordion-toggle',
									{
										'table-accordion-toggle--open': false
									}
								],on:{"click":function($event){$event.preventDefault();return _vm.showNotification($event, -110, 20)}}},[_c('strong',{staticClass:"size1"},[_vm._v("Glaubensmuster entdecken?")]),(_vm.$mq !== 'mobile')?_c('strong',{staticClass:"size2"},[_vm._v("12")]):_vm._e(),(_vm.$mq !== 'mobile')?_c('strong',{staticClass:"size3"},[_vm._v("Vor 2 Stunden")]):_vm._e(),_c('i',{staticClass:"arrow"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"17.121","height":"9.31","viewBox":"0 0 17.121 9.31"}},[_c('path',{attrs:{"id":"Path_11614","data-name":"Path 11614","d":"M7609.614,1069l7.5,7.5,7.5-7.5","transform":"translate(-7608.554 -1067.939)","fill":"none","stroke":"#fff","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}})])])])]),_c('div',{staticClass:"table-row"},[_c('div',{class:[
									'table-accordion-toggle',
									{
										'table-accordion-toggle--open': false
									}
								],on:{"click":function($event){$event.preventDefault();return _vm.showNotification($event, -110, 20)}}},[_c('strong',{staticClass:"size1"},[_vm._v("Übungen gegen stress")]),(_vm.$mq !== 'mobile')?_c('strong',{staticClass:"size2"},[_vm._v("12")]):_vm._e(),(_vm.$mq !== 'mobile')?_c('strong',{staticClass:"size3"},[_vm._v("Vor 2 Stunden")]):_vm._e(),_c('i',{staticClass:"arrow"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"17.121","height":"9.31","viewBox":"0 0 17.121 9.31"}},[_c('path',{attrs:{"id":"Path_11614","data-name":"Path 11614","d":"M7609.614,1069l7.5,7.5,7.5-7.5","transform":"translate(-7608.554 -1067.939)","fill":"none","stroke":"#fff","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}})])])])])]),_c('div',{staticClass:"table-actions"},[_c('a',{staticClass:"btn-square",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showNotification($event, -110, 20)}}},[_vm._v("+ Neues Thema hinzufügen")])])])])])]),_c('NotificationBox',{attrs:{"event":this.event,"showNotification":this.isNotificationVisible,"offsetLeft":this.offsetLeft,"offsetTop":this.offsetTop},on:{"onClose":this.onNotificationClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumbs"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"/home"}},[_vm._v("Übersicht > ")])]),_c('li',[_c('a',{attrs:{"href":"/community"}},[_vm._v("Community >")])]),_c('li',[_c('span',[_vm._v("Dein Einstieg")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__body"},[_c('div',{staticClass:"form__controls"},[_c('input',{staticClass:"search_field ",attrs:{"type":"text","value":"","id":"field-search","placeholder":"Suchen"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-head"},[_c('div',{staticClass:"cell-size1"},[_vm._v("Thema")]),_c('div',{staticClass:"cell-size2"},[_vm._v("Unterhaltungen")]),_c('div',{staticClass:"cell-size3"},[_vm._v("Letzte Änderung")])])}]

export { render, staticRenderFns }