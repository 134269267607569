<template>
	<div
		class="home main main--with-bg main--exercise"
		style="background-image: url(/img/bg-2.svg); background-size: cover"
	>
		<div
			class="shell shell--small d-flex flex-column justify-content-center"
		>
			<div class="cell-center">
				<Loader v-show="this.isLoading" />

				<div v-show="!this.isLoading" class="shell-inner">
					<div class="text-box text-box-less-space-top">
						<h5 class="text-center">Umgang mit Gewohnheiten</h5>

						<div class="text-box__inner">
							<i>
								<img
									src="/img/ico-feature1.svg"
									alt=""
									width="57"
									height="57"
								/>
							</i>

							<p>
								Gewohnheiten im Alltag zu kreieren & zu
								etablieren steigert Dein Wohlbefinden.
							</p>
						</div>
					</div>

					<Audio
						:url="[
							'audio/pillar2/Gewohnheiten_female.mp3',
							'audio/pillar2/Gewohnheiten_male.mp3'
						]"
						playerid="audio-player"
					></Audio>
				</div>
			</div>

			<div class="bottom-actions bottom-actions-with-shell">
				<div class="shell shell--alt">
					<btn-back></btn-back>
					<a
						href="/exercise2-2"
						class="btn btn--orange btn--text-small "
						>Weiter</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BtnBack from '@/components/BtnBack.vue';
import Audio from '@/components/Audio.vue';
import Loader from '@/components/Loader.vue';
export default {
	name: 'Exercise1-1',
	components: {
		Audio,
		BtnBack,
		Loader
	},

	data() {
		return { isLoading: false };
	},
	methods: {
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2700);
		}
	},
	computed: {},
	mounted: function() {
		this.loading();
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
