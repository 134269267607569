<template>
	<div
		class="home main main--with-bg"
		style="background-image: url(/img/bg-1.svg); background-size: cover"
	>
		<div class="shell">
			<div
				class="row justify-content-lg-between justify-content-md-center"
			>
				<div class="col-12 col-md-4 col-lg-3">
					<ul
						class="list-features list-features--alt list-features--text-small"
					>
						<li>
							<div>
								<span class="icon">
									<img src="/img/ico-feature7.svg" alt="" />
								</span>

								<span class="text">
									Absolvierte <br v-if="$mq === 'mobile'" />
									Trainings

									<small class="inactive">3</small>
								</span>
							</div>
						</li>

						<li>
							<div>
								<span class="icon">
									<img src="/img/ico-feature8.svg" alt="" />
								</span>

								<span class="text">
									Trainingsminuten
									<br v-if="$mq === 'mobile'" />gesamt
									<small class="inactive">21</small>
								</span>
							</div>
						</li>

						<li>
							<div>
								<span class="icon">
									<img src="/img/ico-feature9.svg" alt="" />
								</span>

								<span class="text">
									Trainingstage
									<small class="inactive">1</small>
								</span>
							</div>
						</li>

						<li>
							<div>
								<span class="icon">
									<img src="/img/ico-feature10.svg" alt="" />
								</span>

								<span class="text">
									Favorisierte <br v-if="$mq === 'mobile'" />
									Trainings
									<small class="inactive">1</small>
								</span>
							</div>
						</li>
					</ul>

					<div v-if="$mq === 'mobile'" class="scroll-btn-holder">
						<a href="#" v-scroll-to="'#myTabContent'">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="22.237"
								height="12.119"
								viewBox="0 0 22.237 12.119"
							>
								<path
									id="Path_11660"
									data-name="Path 11660"
									d="M10634.705-3867.628l-9.705,9.7,9.705,9.7"
									transform="translate(3869.042 10636.119) rotate(-90)"
									fill="none"
									stroke="#008989"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</a>
					</div>
				</div>

				<div
					class="col-12 col-md-10 col-lg-8 flex-column d-flex justify-content-start"
				>
					<div class="tab-content py-3" id="myTabContent">
						<div
							class="tab-pane fade"
							:class="{ 'active show': isActive('my-activity') }"
							id="my-activity"
						>
							<div class="chart-holder">
								<h2>Sibando Trainings</h2>

								<div class="chart">
									<img src="/img/chart2.svg" alt="" />

									<div class="chart_label chart_label--left">
										<span>Offene Trainings</span>

										<small>47</small>
									</div>
									<div class="chart_label chart_label--right">
										<span>Absolvierte Trainings</span>

										<small>3</small>
									</div>
								</div>
							</div>
						</div>
						<div
							class="tab-pane fade"
							:class="{ 'active show': isActive('my-team') }"
							id="my-team"
						>
							<div class="tab-progress">
								<NotificationBox
									class="notification-box--static"
									:event="this.event"
									:showNotification="true"
									:offsetLeft="0"
									:offsetTop="0"
									@onClose="this.onNotificationClose"
								/>

								<img src="/img/tab-image1.png" alt="" />
							</div>
						</div>

						<div
							class="tab-pane fade"
							:class="{
								'active show': isActive('my-organisation')
							}"
							id="my-organisation"
						>
							<div class="tab-progress">
								<NotificationBox
									class="notification-box--static"
									:event="this.event"
									:showNotification="true"
									:offsetLeft="0"
									:offsetTop="0"
									@onClose="this.onNotificationClose"
								/>

								<img src="/img/tab-image1.png" alt="" />
							</div>
						</div>
					</div>

					<div class="tabs-nav-holder tabs-nav-holder-expandable">
						<ul class="nav-tabs nav-justified">
							<li
								class="nav-item"
								:class="{
									'nav-item--active':
										this.activeItem === 'my-activity'
								}"
							>
								<a
									class="nav-link"
									@click.prevent="setActive('my-activity')"
									:class="{
										active:
											this.activeItem === 'my-activity'
									}"
									href="#my-activity"
									>Meine Aktivitäten</a
								>
							</li>

							<li
								class="nav-item"
								:class="{
									'nav-item--active':
										this.activeItem === 'my-team'
								}"
							>
								<a
									class="nav-link"
									@click.prevent="setActive('my-team')"
									:class="{
										active: this.activeItem === 'my-team'
									}"
									href="#my-team"
								>
									Mein Team
								</a>
							</li>

							<li
								class="nav-item"
								:class="{
									'nav-item--active':
										this.activeItem === 'my-organisation'
								}"
							>
								<a
									class="nav-link"
									@click.prevent="
										setActive('my-organisation')
									"
									:class="{
										active:
											this.activeItem ===
											'my-organisation'
									}"
									href="#my-organisation"
								>
									Meine Organisation
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<NotificationBox
			:event="this.event"
			:showNotification="this.isNotificationVisible"
			:offsetLeft="this.offsetLeft"
			:offsetTop="this.offsetTop"
			@onClose="this.onNotificationClose"
		/>
	</div>
</template>

<script>
import NotificationBox from '@/components/NotificationBox.vue';
export default {
	name: 'Dashboard',
	components: {
		NotificationBox
	},
	data() {
		return {
			activeItem: 'my-activity',
			isNotificationVisible: false,
			offsetLeft: 0,
			offsetTop: 0,
			event: {}
		};
	},
	computed: {},
	methods: {
		isActive(menuItem) {
			console.log(menuItem);
			return this.activeItem === menuItem;
		},
		setActive(menuItem) {
			// console.log(menuItem);
			this.activeItem = menuItem;
		},
		showNotification(event, x, y) {
			this.isNotificationVisible = true;
			this.event = event;
			this.offsetLeft = x;
			this.offsetTop = y;
		},
		onNotificationClose() {
			this.isNotificationVisible = false;
		}
	},
	mounted: function() {
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>

<style lang="scss" scoped>
.main {
	flex-direction: column;

	.shell {
		flex-direction: column;
		display: flex;
		flex-grow: 1;
	}
	.row {
		flex-grow: 1;
	}
}

.flex-column {
	flex-direction: column;
	justify-content: flex-end;
}
</style>
