<template>
	<div>
		<div
			class="notification-box"
			v-if="this.showNotification"
			:style="{
				left: $mq === 'mobile' ? 50 + '%' : this.positionX + 'px',

				top:
					$mq === 'mobile'
						? 50 + '%'
						: this.event.pageY + this.offsetTop + 'px'
			}"
			v-click-outside="closeOnOutsideClick"
		>
			<button class="btn-close" @click="closeNotification">
				<i>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="7.914"
						height="7.917"
						viewBox="0 0 7.914 7.917"
					>
						<g
							id="Group_4748"
							data-name="Group 4748"
							transform="translate(-863.293 -564.793)"
						>
							<path
								id="Path_11651"
								data-name="Path 11651"
								d="M13418.578-3034.018l6.5,6.5"
								transform="translate(-12554.578 3599.518)"
								fill="none"
								stroke="#000"
								stroke-linecap="round"
								stroke-width="1"
							/>
							<path
								id="Path_11652"
								data-name="Path 11652"
								d="M0,0,6.5,6.5"
								transform="translate(870.498 565.503) rotate(90)"
								fill="none"
								stroke="#000"
								stroke-linecap="round"
								stroke-width="1"
							/>
						</g>
					</svg>
				</i>
			</button>

			<p>
				Diese Funktion wird <br />
				in der Vollversion freigeschaltet!
			</p>
		</div>

		<span
			v-if="this.showNotification && $mq === 'mobile'"
			class="overlay"
		></span>
	</div>
</template>

<script>
export default {
	name: 'NotificationBox',
	props: ['event', 'offsetLeft', 'offsetTop', 'showNotification'],

	data() {
		return {
			clickedCount: 0
		};
	},
	methods: {
		closeNotification() {
			this.clickedCount = 0;
			this.$emit('onClose');
		},
		closeOnOutsideClick() {
			if (this.clickedCount >= 1) {
				this.closeNotification();
			} else {
				this.clickedCount++;
			}
		}
	},
	computed: {
		positionX: function() {
			const width = window.innerWidth;
			let offsetX;

			if (this.event.pageX + this.offsetLeft + 209 > width) {
				offsetX = width - 209;
			} else {
				offsetX = this.event.pageX + this.offsetLeft;
			}

			if (offsetX < 0) {
				offsetX = 0;
			}

			return offsetX;
		}
	}
};
</script>

<style lang="css" scoped></style>
