<template>
	<div
		class="home main main--with-bg main--roadmap"
		style="background-color: #EFFFFF"
	>
		<div class="shell d-flex flex-column justify-content-center">
			<div class="cell-center">
				<Loader v-show="this.isLoading" />

				<div v-show="!this.isLoading" class="roadmap shell-inner">
					<!-- <img
						v-if="$mq !== 'mobile'"
						src="/img/roadmap6.svg"
						alt=""
					/>

					<img
						v-if="$mq === 'mobile'"
						src="/img/map-mobile6.svg"
						alt=""
					/> -->

					<MapDesktop v-if="$mq !== 'mobile'" :progress="95" />
					<MapMobile v-if="$mq === 'mobile'" :progress="95" />

					<ul class="list-labels">
						<li>
							<span>DEIN EINSTIEG</span>
						</li>

						<li>
							<span>WAHRNEHMUNG & <br />ACHTSAMKEIT</span>
						</li>

						<li>
							<span>Bewusstsein</span>
						</li>

						<li>
							<span>SELBSTREFLEKTION</span>
						</li>

						<li>
							<span>INTERAKTION & <br />TEAMWORK</span>
						</li>

						<li>
							<span>ZIELSETZUNG & <br />FOKUSSIERUNG</span>
						</li>
					</ul>
				</div>
			</div>

			<div class="bottom-actions bottom-actions--right">
				<div class="shell shell--alt">
					<a href="/my-way" class="btn btn--orange btn--text-small"
						>Zu My Way</a
					>

					<router-link
						class="btn btn--dark btn--text-small "
						:to="{ name: 'Home', params: { activeSlide: 6 } }"
					>
						Zur Übersicht
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import MapDesktop from '@/components/MapDesktop.vue';
import MapMobile from '@/components/MapMobile.vue';
export default {
	name: 'Exercise6-3',
	components: {
		Loader,
		MapDesktop,
		MapMobile
	},
	data() {
		return {
			isLoading: false
		};
	},
	methods: {
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2700);
		}
	},
	computed: {},
	mounted: function() {
		this.loading();
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
