<template>
	<div
		class="home main main--with-bg main--exercise"
		style="background-image: url(/img/bg-2.svg); background-size: cover"
	>
		<div
			class="shell shell--small d-flex flex-column justify-content-center"
		>
			<div class="cell-center">
				<Loader v-show="this.isLoading" />

				<div v-show="!this.isLoading" class="shell-inner">
					<div class="text-box text-box-less-space">
						<h5 class="text-center">Deine Gewohnheiten</h5>

						<div class="text-box__inner">
							<i>
								<img
									src="/img/ico-feature1.svg"
									alt=""
									width="57"
									height="57"
								/>
							</i>

							<p>
								Hast Du bereits die eine oder andere Deiner
								Gewohnheiten herausbekommen? Falls nicht,
								schalte die Kamera ein und fokussiere Dich einen
								Augenblick auf Dich. Die gezeigten Beispiele
								können Dir helfen, Gewohnheiten bei Dir zu
								entdecken.
							</p>
						</div>
					</div>

					<div class="image-box">
						<!-- <img src="/img/image-12.png" alt="" /> -->
						<img src="/img/image-12-new.svg" alt="" />
					</div>

					<div class="text-center">
						<button
							class="button-circle btn-no-style"
							@click="showNotification($event, -110, -20)"
						>
							<img
								src="/img/ico-feature13.svg"
								width="57"
								height="57"
								alt=""
							/>
						</button>
					</div>
				</div>
			</div>

			<div class="bottom-actions bottom-actions-with-shell">
				<div class="shell shell--alt">
					<btn-back></btn-back>
					<a
						href="exercise2-3"
						class="btn btn--orange btn--text-small "
						>Weiter</a
					>
				</div>
			</div>

			<NotificationBox
				:event="this.event"
				:showNotification="this.isNotificationVisible"
				:offsetLeft="this.offsetLeft"
				:offsetTop="this.offsetTop"
				@onClose="this.onNotificationClose"
			/>
		</div>
	</div>
</template>

<script>
import NotificationBox from '@/components/NotificationBox.vue';
import BtnBack from '@/components/BtnBack.vue';
import Loader from '@/components/Loader.vue';
export default {
	name: 'Exercise2-2',
	components: { NotificationBox, BtnBack, Loader },
	data() {
		return {
			isNotificationVisible: false,
			offsetLeft: 0,
			offsetTop: 0,
			event: {},
			isLoading: false
		};
	},
	methods: {
		showNotification(event, x, y) {
			this.isNotificationVisible = true;
			this.event = event;
			this.offsetLeft = x;
			this.offsetTop = y;
		},
		onNotificationClose() {
			this.isNotificationVisible = false;
		},
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2700);
		}
	},
	computed: {},
	mounted: function() {
		this.loading();
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
