<template>
	<div class="login">
		<div class="shell">
			<div class="row justify-content-md-center">
				<div class="col-md-10">
					<img
						alt="Vue logo"
						src="img/logo-dark-large2.svg"
						class="login-logo"
						width="217"
						height="43"
					/>

					<div class="form-login">
						<form action="" @submit="checkForm" method="post">
							<h6
								style="padding-bottom:10px"
								v-if="this.msg.length > 0"
							>
								{{ this.msg }}
							</h6>

							<div class="form__body">
								<div
									class="form__controls form__controls--with-icon"
								>
									<i>
										<svg
											id="Group_330"
											data-name="Group 330"
											xmlns="http://www.w3.org/2000/svg"
											width="12.839"
											height="17.5"
											viewBox="0 0 12.839 17.5"
										>
											<circle
												id="Ellipse_9"
												data-name="Ellipse 9"
												cx="4.04"
												cy="4.04"
												r="4.04"
												transform="translate(2.49)"
												fill="#efffff"
											/>
											<path
												id="Path_10667"
												data-name="Path 10667"
												d="M5632.024-1222.2v3.633a1.134,1.134,0,0,1-1.132,1.137H5620.32a1.136,1.136,0,0,1-1.135-1.137v-3.231a6.425,6.425,0,0,1,5.341-6.332,6.31,6.31,0,0,1,1.084-.093,6.316,6.316,0,0,1,1.084.093A6.42,6.42,0,0,1,5632.024-1222.2Z"
												transform="translate(-5619.185 1234.926)"
												fill="#efffff"
											/>
										</svg>
									</i>

									<input
										type="text"
										class="field field--with-icon"
										value=""
										id="field-username"
										placeholder="Name"
										v-model="username"
									/>
								</div>

								<div
									class="form__controls form__controls--with-icon"
								>
									<i>
										<svg
											id="Group_329"
											data-name="Group 329"
											xmlns="http://www.w3.org/2000/svg"
											width="13.661"
											height="19.427"
											viewBox="0 0 13.661 19.427"
										>
											<path
												id="Path_10633"
												data-name="Path 10633"
												d="M5700.249-1248.641v3.282h-1.536v-3.282a3.227,3.227,0,0,0-3.219-3.223,3.228,3.228,0,0,0-3.223,3.223v3.282h-1.536v-3.282a4.764,4.764,0,0,1,4.759-4.759A4.762,4.762,0,0,1,5700.249-1248.641Z"
												transform="translate(-5688.661 1253.4)"
												fill="#efffff"
											/>
											<path
												id="Path_10634"
												data-name="Path 10634"
												d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
												transform="translate(-5683.755 1234.371)"
												fill="#efffff"
											/>
										</svg>
									</i>

									<input
										type="password"
										class="field field--with-icon"
										value=""
										id="field-password"
										placeholder="Passwort"
										v-model="password"
									/>
								</div>
							</div>

							<div class="form__actions">
								<button
									href="/login"
									class="btn btn--white btn-round btn--alt"
								>
									Anmelden
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'Login',
	components: {},
	data() {
		return {
			username: '',
			password: '',
			msg: ''
		};
	},
	methods: {
		checkForm: function(e) {
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			};
			fetch(
				`api?username=${this.username}&password=${this.password}`,
				requestOptions
			)
				.then((resp) => {
					console.log(resp);

					if (resp.status === 200) {
						console.log('success');
						localStorage.setItem('authUser', this.username);
						const timeExpireLogin =
							Date.now() + 12 * 60 * 60 * 1000;
						this.msg = '';

						localStorage.setItem(
							'loginExpireTime',
							timeExpireLogin
						);

						this.$router.push('/home');
					}

					if (resp.status === 404) {
						this.msg = 'Ungültiger Nutzername oder Passwort!';
					}

					if (resp.status === 400) {
						this.msg =
							'Die Anzahl der erlaubten Logins wurde überschritten.';
					}
				})
				.then((data) => {})
				// .then((resp) => resp.json())
				// .then((data) => {})
				.catch((error) => {
					// handle error
					console.log(error);
				});

			e.preventDefault();
		}
	},
	mounted: function() {}
};
</script>

<style lang="scss"></style>
