<template>
	<div
		class="home main main--with-bg main--exercise"
		style="background-image: url(/img/bg-2.svg); background-size: cover"
	>
		<div
			class="shell shell--small d-flex flex-column justify-content-center"
		>
			<div class="cell-center">
				<Loader v-show="this.isLoading" />

				<div v-show="!this.isLoading" class="shell-inner relative">
					<div class="text-box text-box-less-space-top">
						<h5 class="text-center">Dehnübungen</h5>

						<div class="text-box__inner">
							<i>
								<img
									src="/img/ico-feature1.svg"
									alt=""
									width="57"
									height="57"
								/>
							</i>

							<p>
								Unsere Schultern tragen die Last des Alltags,
								denn wenn wir gestresst sind, verspannen sie
								sich schnell. Wenn Du Deine Schultern lockerst,
								wirst Du auch einiges an Anspannung los.
								Kreisende Bewegungen sind eine Wohltat für die
								Schultermuskulatur.
							</p>
						</div>
					</div>

					<Audio
						:url="[
							'audio/pillar6/Warmup Dehnuebung_female.mp3',
							'audio/pillar6/Warmup Dehnuebung_male.mp3'
						]"
						playerid="audio-player"
					></Audio>

					<img
						src="/img/audio-decoration-6.svg"
						class="audio-decoration audio-decoration-6"
						alt=""
					/>
				</div>
			</div>

			<div class="bottom-actions bottom-actions-with-shell">
				<div class="shell shell--alt">
					<btn-back></btn-back>
					<a
						href="/warm-up6-2"
						class="btn btn--orange btn--text-small "
						>Weiter</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import BtnBack from '@/components/BtnBack.vue';
import Audio from '@/components/Audio.vue';
export default {
	name: 'WarmUp-1',
	components: {
		Audio,
		BtnBack,
		Loader
	},

	data() {
		return {
			isLoading: false
		};
	},
	methods: {
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2700);
		}
	},
	computed: {},
	mounted() {
		this.loading();
	},
	mounted: function() {
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
