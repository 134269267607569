<template>
	<div
		class="home main main--with-bg main--exercise"
		style="background-image: url(/img/bg-2.svg); background-size: cover"
	>
		<div class="shell d-flex flex-column ">
			<div class="msg text-center w-100 flex-grow-1">
				<h1>Warm up <br />time!</h1>
			</div>

			<div class="bottom-actions bottom-actions-with-shell">
				<div class="shell shell--alt">
					<btn-back></btn-back>
					<a
						href="/warm-up1-1"
						class="btn btn--orange btn--text-small "
						>Training starten!</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BtnBack from '@/components/BtnBack.vue';
export default {
	name: 'WarmUp1',
	components: { BtnBack },
	data() {
		return {
			isAccordionExpanded: false
		};
	},
	methods: {
		isActive(menuItem) {
			return this.activeItem === menuItem;
		},
		setActive(menuItem) {
			this.activeItem = menuItem;
		},
		expandAccordion() {
			this.isAccordionExpanded = !this.isAccordionExpanded;
		}
	},
	mounted: function() {
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
