import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMq from 'vue-mq';
import vOutsideEvents from 'vue-outside-events';
import VueScrollTo from 'vue-scrollto';
import VueSlideToggle from 'vue-slide-toggle';
import VueYoutube from 'vue-youtube';
import VueCircleSlider from 'vue-circle-slider';
import RoundSlider from 'vue-round-slider';
// import VueAnalytics from 'vue-ua';
import Hotjar from 'vue-hotjar';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import device from 'vue-device-detector';

Vue.use(device);

Vue.use(LottieAnimation);

Vue.use(Loading);

import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
	config: { id: 'G-Q5LT9LJXXL' }
});

// Vue.use(VueAnalytics, {
// 	trackingId: 'G-Q5LT9LJXXL',
// 	appName: 'Sibando',
// 	appVersion: '1.0'
// });

Vue.use(Hotjar, {
	id: '2352120',
	isProduction: true,
	snippetVersion: 6
});

Vue.use(RoundSlider);
Vue.use(VueCircleSlider);
Vue.use(VueYoutube);
Vue.use(VueSlideToggle);
Vue.use(VueScrollTo);

Vue.use(VueMq, {
	breakpoints: {
		mobile: 767,
		tablet: 1023,
		laptop: 1200,
		desktop: Infinity
	}
});

Vue.use(vOutsideEvents);

import './assets/sass/style.scss';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
