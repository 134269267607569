<template>
    <div class="nav">
        <ul>
            <li>
                <router-link class="v-skip" :to="{ name: 'MyWay' }">
                    My Way
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Dashboard' }">
                    Dashboard
                </router-link>
            </li>
            <li>
                <router-link class="v-skip" :to="{ name: 'Community' }">
                    Community
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Support' }">
                    Support & Feedback
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Nav'
};
</script>

<style scoped lang="scss"></style>
