<template>
	<div
		class="home main main--with-bg"
		style="background-image: url(/img/bg-1.svg); background-size: cover"
	>
		<div
			class="shell shell--small d-flex align-items-center justify-content-center"
		>
			<ul class="list-features list-features--spaced">
				<li>
					<a
						href="mailto:info@sibando.com?subject=Fragen und Feedback zu Sibando"
					>
						<span class="icon">
							<img src="/img/ico-feature4.svg" alt="" />
						</span>

						<span class="text">
							Kontakt
						</span>
					</a>
				</li>

				<li>
					<a
						href=""
						@click.prevent="showNotification($event, -110, 20)"
					>
						<span class="icon">
							<img src="/img/ico-feature5.svg" alt="" />
						</span>

						<span class="text">
							FAQS
						</span>
					</a>
				</li>

				<li>
					<a
						href=""
						@click.prevent="showNotification($event, -110, 20)"
					>
						<span class="icon">
							<img src="/img/ico-feature6.svg" alt="" />
						</span>

						<span class="text">
							Bibliothek
						</span>
					</a>
				</li>
			</ul>
		</div>
		<NotificationBox
			:event="this.event"
			:showNotification="this.isNotificationVisible"
			:offsetLeft="this.offsetLeft"
			:offsetTop="this.offsetTop"
			@onClose="this.onNotificationClose"
		/>
	</div>
</template>

<script>
import NotificationBox from '@/components/NotificationBox.vue';
import HintEye from '@/components/HintEye.vue';

export default {
	name: 'Home',
	components: {
		HintEye,
		NotificationBox
	},
	data() {
		return {
			isNotificationVisible: false,
			offsetLeft: 0,
			offsetTop: 0,
			event: {}
		};
	},
	methods: {
		showNotification(event, x, y) {
			this.isNotificationVisible = true;
			this.event = event;
			this.offsetLeft = x;
			this.offsetTop = y;
		},
		onNotificationClose() {
			this.isNotificationVisible = false;
		}
	},
	mounted: function() {
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
