var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.showNotification)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeOnOutsideClick),expression:"closeOnOutsideClick"}],staticClass:"notification-box",style:({
			left: _vm.$mq === 'mobile' ? 50 + '%' : this.positionX + 'px',

			top:
				_vm.$mq === 'mobile'
					? 50 + '%'
					: this.event.pageY + this.offsetTop + 'px'
		})},[_c('button',{staticClass:"btn-close",on:{"click":_vm.closeNotification}},[_c('i',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"7.914","height":"7.917","viewBox":"0 0 7.914 7.917"}},[_c('g',{attrs:{"id":"Group_4748","data-name":"Group 4748","transform":"translate(-863.293 -564.793)"}},[_c('path',{attrs:{"id":"Path_11651","data-name":"Path 11651","d":"M13418.578-3034.018l6.5,6.5","transform":"translate(-12554.578 3599.518)","fill":"none","stroke":"#000","stroke-linecap":"round","stroke-width":"1"}}),_c('path',{attrs:{"id":"Path_11652","data-name":"Path 11652","d":"M0,0,6.5,6.5","transform":"translate(870.498 565.503) rotate(90)","fill":"none","stroke":"#000","stroke-linecap":"round","stroke-width":"1"}})])])])]),_vm._m(0)]):_vm._e(),(this.showNotification && _vm.$mq === 'mobile')?_c('span',{staticClass:"overlay"}):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Diese Funktion wird "),_c('br'),_vm._v(" in der Vollversion freigeschaltet! ")])}]

export { render, staticRenderFns }