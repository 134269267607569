import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Start from '../views/Start.vue';
import MyWay from '../views/MyWay.vue';
import MyWayInner from '../views/MyWayInner.vue';
import Dashboard from '../views/Dashboard.vue';
import Support from '../views/Support&Feedback.vue';
import Community from '../views/Community.vue';
import CommunityInner from '../views/CommunityInner.vue';
import Pillar1 from '../views/Pillar1.vue';
import Pillar2 from '../views/Pillar2.vue';
import Pillar3 from '../views/Pillar3.vue';
import Pillar4 from '../views/Pillar4.vue';
import Pillar5 from '../views/Pillar5.vue';
import Pillar6 from '../views/Pillar6.vue';
import Exercise1 from '../views/Exercise1';
import Exercise1_3 from '../views/Exercise1-3';
import Exercise2 from '../views/Exercise2';
import Exercise3 from '../views/Exercise3';
import Exercise4 from '../views/Exercise4';
import Exercise4_1 from '../views/Exercise4-1';
import Exercise4_2 from '../views/Exercise4-2';
import Exercise4_3 from '../views/Exercise4-3';
import Exercise5 from '../views/Exercise5';
import Exercise5_1 from '../views/Exercise5-1';
import Exercise5_2 from '../views/Exercise5-2';
import Exercise5_3 from '../views/Exercise5-3';

import Exercise1_1 from '../views/Exercise1-1';
import Exercise1_2 from '../views/Exercise1-2';

import Exercise2_1 from '../views/Exercise2-1';
import Exercise2_2 from '../views/Exercise2-2';
import Exercise2_3 from '../views/Exercise2-3';
import Exercise2_4 from '../views/Exercise2-4';
import Exercise3_1 from '../views/Exercise3-1';
import WarmUp1 from '../views/WarmUp1';
import WarmUp6 from '../views/WarmUp6';
import WarmUp6_1 from '../views/WarmUp6-1';
import WarmUp6_2 from '../views/WarmUp6-2';
import WarmUp6_3 from '../views/WarmUp6-3';

import WarmUp1_1 from '../views/WarmUp1-1';
import WarmUp1_2 from '../views/WarmUp1-2';
import WarmUp1_3 from '../views/WarmUp1-3';
import WarmUp2_1 from '../views/WarmUp2-1';
import WarmUp2_2 from '../views/WarmUp2-2';
import WarmUp2_3 from '../views/WarmUp2-3';
import WarmUp3_3 from '../views/WarmUp3-3';
import WarmUp3_2 from '../views/WarmUp3-2';
import WarmUp2 from '../views/WarmUp2';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Start',
        component: Start
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: '/pillar1',
        name: 'Pillar1',
        component: Pillar1,
        meta: { requiresAuth: true }
    },
    {
        path: '/pillar2',
        name: 'Pillar2',
        component: Pillar2
    },
    {
        path: '/pillar3',
        name: 'Pillar3',
        component: Pillar3
    },
    {
        path: '/pillar4',
        name: 'Pillar4',
        component: Pillar4
    },
    {
        path: '/pillar5',
        name: 'Pillar5',
        component: Pillar5
    },
    {
        path: '/pillar6',
        name: 'Pillar6',
        component: Pillar6
    },
    {
        path: '/exercise1',
        name: 'Exercise1',
        component: Exercise1
    },
    {
        path: '/exercise2',
        name: 'Exercise2',
        component: Exercise2
    },
    {
        path: '/exercise3',
        name: 'Exercise3',
        component: Exercise3
    },
    {
        path: '/exercise4',
        name: 'Exercise4',
        component: Exercise4
    },
    {
        path: '/exercise4-1',
        name: 'Exercise4-1',
        component: Exercise4_1
    },
    {
        path: '/exercise4-2',
        name: 'Exercise4-2',
        component: Exercise4_2
    },
    {
        path: '/exercise4-3',
        name: 'Exercise4-3',
        component: Exercise4_3
    },
    {
        path: '/exercise5',
        name: 'Exercise5',
        component: Exercise5
    },

    {
        path: '/exercise5-1',
        name: 'Exercise5-1',
        component: Exercise5_1
    },

    {
        path: '/exercise5-2',
        name: 'Exercise5-2',
        component: Exercise5_2
    },
    {
        path: '/exercise5-3',
        name: 'Exercise5-3',
        component: Exercise5_3
    },
    {
        path: '/exercise1-1',
        name: 'Exercise1_1',
        component: Exercise1_1
    },
    {
        path: '/exercise1-2',
        name: 'Exercise1_2',
        component: Exercise1_2
    },
    {
        path: '/exercise1-3',
        name: 'Exercise1_3',
        component: Exercise1_3
    },
    {
        path: '/exercise2-1',
        name: 'Exercise2_1',
        component: Exercise2_1
    },
    {
        path: '/exercise2-2',
        name: 'Exercise2_2',
        component: Exercise2_2
    },
    {
        path: '/exercise2-3',
        name: 'Exercise2_3',
        component: Exercise2_3
    },
    {
        path: '/exercise2-4',
        name: 'Exercise2_4',
        component: Exercise2_4
    },
    {
        path: '/exercise3-1',
        name: 'Exercise3_1',
        component: Exercise3_1
    },
    {
        path: '/warm-up1',
        name: 'WarmUp1',
        component: WarmUp1
    },
    {
        path: '/warm-up1-1',
        name: 'WarmUp1_1',
        component: WarmUp1_1
    },
    {
        path: '/warm-up1-2',
        name: 'WarmUp1_2',
        component: WarmUp1_2
    },
    {
        path: '/warm-up1-3',
        name: 'WarmUp1_3',
        component: WarmUp1_3
    },
    {
        path: '/warm-up2-1',
        name: 'WarmUp2_1',
        component: WarmUp2_1
    },
    {
        path: '/warm-up2-2',
        name: 'WarmUp2_2',
        component: WarmUp2_2
    },
    {
        path: '/warm-up2-3',
        name: 'WarmUp2_3',
        component: WarmUp2_3
    },
    {
        path: '/warm-up3-3',
        name: 'WarmUp3_3',
        component: WarmUp3_3
    },
    {
        path: '/warm-up3-2',
        name: 'WarmUp3_2',
        component: WarmUp3_2
    },
    {
        path: '/warm-up2',
        name: 'WarmUp2',
        component: WarmUp2
    },
    {
        path: '/warm-up6',
        name: 'WarmUp6',
        component: WarmUp6
    },

    {
        path: '/warm-up6-1',
        name: 'WarmUp6-1',
        component: WarmUp6_1
    },

    {
        path: '/warm-up6-2',
        name: 'WarmUp6-2',
        component: WarmUp6_2
    },
    {
        path: '/warm-up6-3',
        name: 'WarmUp6-3',
        component: WarmUp6_3
    },
    {
        path: '/my-way',
        name: 'MyWay',
        component: MyWay
    },
    {
        path: '/my-way/:id',
        name: 'MyWayInner',
        component: MyWayInner
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/community',
        name: 'Community',
        component: Community
    },
    {
        path: '/community/:id',
        name: 'CommunityInner',
        component: CommunityInner
    },
    {
        path: '/support',
        name: 'Support',
        component: Support
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});

export default router;
