<template>
    <div class="hint-eye">
        <div class="shell">
            <div class="hint-eye-inner">
                <a
                    href=""
                    class="icon"
                    @click.prevent="toggle()"
                    v-click-outside="close"
                >
                    <template v-if="!show">
                        <img src="/img/ico-eye.svg" alt="" />
                    </template>

                    <template v-if="show">
                        <img src="/img/ico-eye-closed.svg" alt="" />
                    </template>
                </a>

                <transition name="fade">
                    <span v-if="show" key="message-box" class="msg">
                        {{ msg }}

                        <a
                            href=""
                            class="button-close"
                            @click.prevent="toggle()"
                        >
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7.914"
                                    height="7.917"
                                    viewBox="0 0 7.914 7.917"
                                >
                                    <g
                                        id="Group_4770"
                                        data-name="Group 4770"
                                        transform="translate(-863.293 -564.793)"
                                    >
                                        <path
                                            id="Path_11651"
                                            data-name="Path 11651"
                                            d="M13418.578-3034.018l6.5,6.5"
                                            transform="translate(-12554.578 3599.518)"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                        <path
                                            id="Path_11652"
                                            data-name="Path 11652"
                                            d="M0,0,6.5,6.5"
                                            transform="translate(870.498 565.503) rotate(90)"
                                            fill="none"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                    </g>
                                </svg>
                            </i>
                        </a>
                    </span>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
//import Nav from '@/components/Nav.vue';
export default {
    name: 'HintEye',
    //components: { Nav },
    props: ['msg'],
    data() {
        return {
            show: true
        };
    },
    methods: {
        toggle: function() {
            this.show = !this.show;
        },
        close: function(event) {
            if (
                this.show === true &&
                !event.target.classList.contains('v-skip')
            ) {
                this.show = false;
            }
        }
    }
};
</script>

<style scoped lang="scss"></style>
