<template>
	<div class="audio-player">
		<div>
			<audio style="display:none" ref="player" :id="playerid">
				<source :src="this.audioUrl" type="audio/mpeg" />
			</audio>
		</div>

		<div
			class="audio-src-toggle"
			:class="[{ active: this.currentFileIndex === 0 }]"
		>
			<div class="toggle-inner" @click="toggleSrc">
				<span class="text">Sprecher wechseln</span>

				<div class="toggle">
					<div class="thumb"></div>
				</div>
			</div>
		</div>

		<div class="audio-wrap">
			<div class="inner">
				<round-slider
					v-model="playbackTime"
					:max="audioDuration"
					start-angle="90"
					end-angle="+360"
					line-cap="round"
					:radius="$mq === 'mobile' ? 70 : 113"
					:width="$mq === 'mobile' ? 4 : 5"
					pathColor="#fff"
					rangeColor="#ffbe46"
					:handleSize="$mq === 'mobile' ? 17 : 26"
					editableTooltip="true"
				/>

				<button class="btn-play" @click="toggleAudio()">
					<svg
						v-show="!isPlaying"
						xmlns="http://www.w3.org/2000/svg"
						:width="$mq === 'mobile' ? 30 : 61"
						:height="$mq === 'mobile' ? 40 : 79"
						viewBox="0 0 61 79"
						preserveAspectRatio="xMidYMid meet"
					>
						<g
							data-name="Polygon 9"
							fill="none"
							stroke-linecap="round"
							stroke-linejoin="round"
						>
							<path d="M61 39.5L0 79V0z" />
							<path
								d="M51.801 39.5L5 9.194v60.612L51.801 39.5m9.199 0L0 79V0l61 39.5z"
								fill="#efffff"
							/>
						</g>
					</svg>

					<svg
						v-show="isPlaying"
						:width="$mq === 'mobile' ? 13 : 26"
						:height="$mq === 'mobile' ? 30 : 61"
						viewBox="0 0 26 61"
						preserveAspectRatio="xMidYMid meet"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g fill="white" fill-rule="evenodd">
							<path d="M0 0h5v61H0zM21 0h5v61h-5z" />
						</g>
					</svg>
				</button>
			</div>
			<div class="btn-seek-wrapper">
				<button
					class="btn-seek btn-seek--backwards"
					@click="seekBackward"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						:width="$mq === 'mobile' ? 43 : 86.914"
						:height="$mq === 'mobile' ? 20 : 39.527"
						viewBox="0 0 86 40"
						preserveAspectRatio="xMidYMid meet"
					>
						<g
							data-name="Group 4728"
							fill="none"
							stroke="#efffff"
							stroke-linecap="round"
							stroke-width="4"
						>
							<path
								data-name="Path 11648"
								d="M15.109 22.027s32.068-28.37 69 0"
								stroke-dasharray="10"
							/>
							<path
								data-name="Path 11649"
								d="M14.654 3.985l-1.819 20.788 20.787 1.818"
								stroke-linejoin="round"
							/>
						</g>
					</svg>
				</button>

				<button
					class="btn-seek btn-seek--forwards"
					@click="seekForward"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						:width="$mq === 'mobile' ? 43 : 86.912"
						:height="$mq === 'mobile' ? 20 : 39.527"
						viewBox="0 0 86 40"
						preserveAspectRatio="xMidYMid meet"
					>
						<g
							data-name="Group 4729"
							fill="none"
							stroke="#efffff"
							stroke-linecap="round"
							stroke-width="4"
						>
							<path
								data-name="Path 11648"
								d="M71.806 22.027s-32.068-28.37-69 0"
								stroke-dasharray="10"
							/>
							<path
								data-name="Path 11649"
								d="M72.259 3.984l1.82 20.788L53.29 26.59"
								stroke-linejoin="round"
							/>
						</g>
					</svg>
				</button>
			</div>
		</div>

		<div
			class="w-3/4 bg-gray-200 border border-gray-300 px-2 pt-2 mt-4 shadow-md"
			style="margin: auto;"
		>
			<div id="player-row" class="inline-flex flex-wrap w-full max-w-5xl">
				<div
					id="progress-bar"
					class="flex-grow bg-white border border-blue-200"
				>
					<div class="overlay-container  w-full h-full">
						<!-- Show loading indicator until audio has been loaded -->

						<div
							v-show="
								!audioLoaded &
									!this.$device.iphone &
									!this.$device.ipad
							"
							class="w-full absolute top-0 bottom-0 right-0 left-0 px-2 pointer-events-none text-center"
							style="color: #94bcec"
						>
							Loading please wait...
						</div>

						<!-- Time -->

						<div
							v-show="audioLoaded || this.$device.iphone"
							class="audio-time"
						>
							<span class="text-sm" v-html="elapsedTime()">
								00:00
							</span>
							-

							<span class="text-sm" v-html="totalTime()">
								00:00
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['url', 'playerid'],
	/**
	 * playbackTime = local var that syncs to audio.currentTime
	 * audioDuration = duration of audio file in seconds
	 * isPlaying = boolean (true if audio is playing)
	 *
	 **/
	data() {
		return {
			playbackTime: 0,
			audioDuration: 100,
			audioLoaded: false,
			isPlaying: false,
			seekTime: 5,
			//audioOption: 'female',
			currentFileIndex: 0,
			track: this.audioUrl
		};
	},
	methods: {
		//Set the range slider max value equal to audio duration
		initSlider() {
			var audio = this.$refs.player;
			if (audio) {
				this.audioDuration = Math.round(audio.duration);
			}
		},
		//Convert audio current time from seconds to min:sec display
		convertTime(seconds) {
			const format = (val) => `0${Math.floor(val)}`.slice(-2);
			var hours = seconds / 3600;
			var minutes = (seconds % 3600) / 60;
			return [minutes, seconds % 60].map(format).join(':');
		},
		//Show the total duration of audio file
		totalTime() {
			var audio = this.$refs.player;
			if (audio) {
				var seconds = audio.duration;
				return this.convertTime(seconds);
			} else {
				return '00:00';
			}
		},
		//Display the audio time elapsed so far
		elapsedTime() {
			var audio = this.$refs.player;
			if (audio) {
				var seconds = audio.currentTime;
				return this.convertTime(seconds);
			} else {
				return '00:00';
			}
		},
		//Playback listener function runs every 100ms while audio is playing
		playbackListener(e) {
			var audio = this.$refs.player;
			//Sync local 'playbackTime' var to audio.currentTime and update global state
			this.playbackTime = audio.currentTime;

			//console.log("update: " + audio.currentTime);
			//Add listeners for audio pause and audio end events
			audio.addEventListener('ended', this.endListener);
			audio.addEventListener('pause', this.pauseListener);
		},
		//Function to run when audio is paused by user
		pauseListener() {
			this.isPlaying = false;
			this.listenerActive = false;
			this.cleanupListeners();
		},
		//Function to run when audio play reaches the end of file
		endListener() {
			this.isPlaying = false;
			this.listenerActive = false;
			this.cleanupListeners();
		},
		//Remove listeners after audio play stops
		cleanupListeners() {
			var audio = this.$refs.player;
			audio.removeEventListener('timeupdate', this.playbackListener);
			audio.removeEventListener('ended', this.endListener);
			audio.removeEventListener('pause', this.pauseListener);
			//console.log("All cleaned up!");
		},
		toggleAudio() {
			var audio = this.$refs.player;
			if (audio.paused) {
				audio.play();
				this.isPlaying = true;
			} else {
				audio.pause();
				this.isPlaying = false;
			}
		},
		seekBackward() {
			var audio = this.$refs.player;

			if (this.isPlaying) {
				audio.currentTime -= 5;
			}
		},
		seekForward() {
			var audio = this.$refs.player;

			if (this.isPlaying) {
				audio.currentTime += 5;
			}
		},
		toggleSrc() {
			console.log(this.currentFileIndex);

			this.currentFileIndex === 0
				? (this.currentFileIndex = 1)
				: (this.currentFileIndex = 0);

			var audio = this.$refs.player;

			audio.pause();
			audio.currentTime = 0;
			this.isPlaying = false;

			this.$refs.player.load();
		},
		initPlayer() {
			// nextTick code will run only after the entire view has been rendered
			this.$nextTick(function() {
				var audio = this.$refs.player;
				//Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
				audio.addEventListener(
					'loadedmetadata',
					function(e) {
						this.initSlider();
					}.bind(this)
				);
				// "canplay" HTML Event lets us know audio is ready for play
				audio.addEventListener(
					'canplay',
					function(e) {
						this.audioLoaded = true;
					}.bind(this)
				);
				//Wait for audio to begin play, then start playback listener function
				this.$watch('isPlaying', function() {
					if (this.isPlaying) {
						var audio = this.$refs.player;
						this.initSlider();
						//prevent starting multiple listeners at the same time
						if (!this.listenerActive) {
							this.listenerActive = true;
							//for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
							audio.addEventListener(
								'timeupdate',
								this.playbackListener
							);
						}
					}
				});
				//Update current audio position when user drags progress slider
				this.$watch('playbackTime', function() {
					var audio = this.$refs.player;
					var diff = Math.abs(
						this.playbackTime - this.$refs.player.currentTime
					);

					//Throttle synchronization to prevent infinite loop between playback listener and this watcher
					if (diff > 0.01) {
						this.$refs.player.currentTime = this.playbackTime;
					}
				});
			});
		}
	},

	computed: {
		audioUrl: function() {
			return this.url[this.currentFileIndex];
		}
	},
	mounted: function() {
		this.initPlayer();
		this.$watch('track', function() {
			this.$refs.player.load();
		});
	}
};
</script>

<style lang="scss" scoped></style>
