<template>
	<div class="home main main--with-bg" style="background-color: #EAF4F4">
		<div class="shell d-block d-md-flex align-items-center">
			<Loader class="white-loader" v-show="this.isLoading" />

			<div v-show="!this.isLoading" class="steps">
				<VueSlickCarousel
					ref="swiper"
					v-bind="slider_settings"
					@swipe="this.swipeMethod"
					@afterChange="this.swipeMethod"
					@init="onInitCarousel"
				>
					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--1">
							<a href="/pillar1" class="step__inner">
								<span class="image-holder"
									><img src="/img/step1.svg" alt="" />

									<svg
										width="186"
										height="186"
										xmlns="http://www.w3.org/2000/svg"
										class="line-absolute"
									>
										<path
											d="M185.579 118.939c-5.919 21.085-20.092 40.174-41.362 52.6-44.178 25.786-102.2 13.026-129.636-28.51C-12.855 101.493 1.985 28.217 36.332 8.156c34.347-20.061 110.814-.061 138.2 41.475a86.3 86.3 0 019.217 18.29"
											stroke="#44737D"
											stroke-width=".6"
											fill="none"
											fill-rule="evenodd"
											opacity=".4"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="line line1"
										/>
									</svg>

									<span class="number number--big"
										>1</span
									></span
								>
								<span class="text">Dein Einstieg</span>
							</a>
						</div>
					</div>

					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--2">
							<a href="/pillar2" class="step__inner">
								<span class="image-holder"
									><img src="/img/step2.svg" alt="" />

									<svg
										class="line-absolute"
										width="209"
										height="152"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											class="line line2"
											d="M207.8 106.9c-6.1 18.6-17.8 26.1-30.7 29.9-13 3.9-39.3-3.4-51-2.3-19.8 1.8-37.9 18.2-66.2 16.3-43.2-2.9-62.3-44.2-59-70.6C12.7-13.2 169.7-26.2 203.2 50.1"
											stroke="#44737D"
											stroke-width=".6"
											opacity=".4"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
										/>
									</svg>

									<span class="number number--big"
										>2</span
									></span
								>
								<span class="text"
									>Wahrnehmung & Achtsamkeit</span
								>
							</a>
						</div>
					</div>

					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--3">
							<a href="/pillar3" class="step__inner">
								<span class="image-holder"
									><img src="/img/step3.svg" alt="" />

									<svg
										class=" line-absolute"
										width="208"
										height="185"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M207.1 109.6s.7 25.9-24.4 49.8c-22.6 21.6-65.4 38.8-125.2 7.7-64.5-33.5-67.9-99.4-39-135.6 9.4-11.8 39.4-42.3 72-25.9 14.9 7.5 22.6 25.3 40.1 35.1 14.2 8 44.3 6.6 65.7 33.3"
											stroke="#44737d"
											stroke-width=".6"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
											opacity=".4"
											class="line line3"
										/>
									</svg>
									<span class="number">3</span></span
								>
								<span class="text">Bewusstsein</span>
							</a>
						</div>
					</div>

					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--4">
							<a href="/pillar4" class="step__inner">
								<span class="image-holder"
									><img src="/img/step4.svg" alt="" />

									<svg
										class=" line-absolute"
										xmlns="http://www.w3.org/2000/svg"
										width="190.847"
										height="177.082"
									>
										<g data-name="Group 4755">
											<path
												data-name="Path 10973"
												d="M190.255 104.508a62.468 62.468 0 01-.6 16.539 52.284 52.284 0 01-1.224 5.223c-6.032 20.819-23.769 38.484-45.94 45.755C80.931 192.219 2.062 144.856.321 84.129c-.717-24.885 16.856-47.291 37.44-63.676 9.524-7.547 20.092-14.337 32.145-17.737 20.983-5.878 44.444-.451 62.468 11.039s35.945 32.166 47.107 54.736"
												fill="none"
												stroke="#44737d"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width=".6"
												opacity=".4"
												class="line line4"
											/>
										</g>
									</svg>
									<span class="number">4</span></span
								>
								<span class="text">Selbstreflektion</span>
							</a>
						</div>
					</div>

					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--5">
							<a href="/pillar5" class="step__inner">
								<span class="image-holder"
									><img src="/img/step5.svg" alt="" />

									<svg
										class=" line-absolute"
										xmlns="http://www.w3.org/2000/svg"
										width="190.082"
										height="181.121"
									>
										<g data-name="Group 4757">
											<path
												data-name="Path 10984"
												d="M189.708 135.754c-3.594 12-9.4 22.212-17.337 29.053-30.067 25.9-110.435 20.113-146.441-15.678S-7.865 57.321 30.814 24.018s99.191-31.285 135.177 4.5c11.05 11 18.751 25.6 23.246 41.434"
												fill="none"
												stroke="#44737d"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width=".6"
												opacity=".4"
												class="line line5"
											/>
										</g>
									</svg>
									<span class="number">5</span></span
								>
								<span class="text">Interaktion & Teamwork</span>
							</a>
						</div>
					</div>

					<div class="col col-md-6 col-lg-6 col-xl-4">
						<div class="step step--6">
							<a href="/pillar6" class="step__inner">
								<span class="image-holder"
									><img src="/img/step6.svg" alt="" />

									<svg
										class=" line-absolute"
										width="180"
										height="166"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M170.8 105.8c-18.6 35.3-121.9 109.1-163 7.2C-4 83.8-6.2 49.7 36.5 19.3 78.4-10.5 159.9-7.1 179.1 52.5"
											stroke="#44737d"
											stroke-width=".6"
											fill="none"
											fill-rule="evenodd"
											stroke-linecap="round"
											opacity=".4"
											class="line line6"
										/>
									</svg>
									<span class="number">6</span></span
								>
								<span class="text"
									>Zielsetzung & Fokussierung</span
								>
							</a>
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</div>
	</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import Loader from '@/components/Loader.vue';

export default {
	name: 'Home',
	components: {
		HelloWorld,
		VueSlickCarousel,
		Loader
	},
	data() {
		return {
			isLoading: false,
			slider_settings: {
				dots: true,
				arrows: true,
				infinite: false,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				swipeToSlide: true,
				clickable: true,
				initialSlide: 0,
				responsive: [
					{
						breakpoint: 9999,
						settings: 'unslick'
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: false,
							dots: true
						}
					}
				]
			}
		};
	},
	methods: {
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2700);
		},
		swipeMethod() {
			//console.log(this);
			console.log(this.$refs.swiper);
			//console.log(this.$refs.swiper.$children.clickable);
			//this.$refs.swiper.$children.clickable = true;
			this.$refs.swiper.$children[0].clickable = true;
		},
		onInitCarousel() {
			console.log('our carousel is ready');
		}
	},
	computed: {
		swiper() {
			return this.$refs.swiper.swiper;
		}
	},
	mounted: function() {
		this.loading();
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	},
	created() {
		if (this.$router.history.current.params.activeSlide) {
			console.log(this.$router.history.current.params.activeSlide);
			this.slider_settings.initialSlide =
				this.$router.history.current.params.activeSlide - 1;
		}
	}
};
</script>
