<template>
	<div
		class="home main main--with-bg main--exercise"
		style="background-image: url(/img/bg-2.svg); background-size: cover"
	>
		<div
			class="shell shell--small d-flex flex-column justify-content-center"
		>
			<div class="cell-center">
				<Loader v-show="this.isLoading" />

				<div v-show="!this.isLoading" class="shell-inner  ">
					<div
						class="text-box text-box--white text-box-less-space-bottom text-box-less-space-top"
					>
						<div class="text-box__inner">
							<i>
								<img
									src="/img/ico-feature11.svg"
									alt=""
									width="57"
									height="57"
								/>
							</i>

							<p>
								Deine Stimme verändert sich je nach
								Körperhaltung und erlebten Emotionen. Wenn Du
								lernst, Deine Stimme zu kontrollieren, kannst Du
								sie gezielt einsetzen.
							</p>

							<div class="line">
								<div class="line__inner"></div>
							</div>

							<p>
								Nutze Deine Stimme, um Deinen Zielen näher zu
								kommen.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="bottom-actions bottom-actions-with-shell">
				<div class="shell shell--alt">
					<btn-back></btn-back>
					<a
						href="/exercise5-3"
						class="btn btn--orange btn--text-small "
						>Weiter</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BtnBack from '@/components/BtnBack.vue';
import Loader from '@/components/Loader.vue';
export default {
	name: 'Exercise2-2',
	components: { BtnBack, Loader },
	data() {
		return { isLoading: false };
	},
	methods: {
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2700);
		}
	},
	mounted: function() {
		this.loading();
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
