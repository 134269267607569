<template>
	<div
		class="home main main--with-bg"
		style="background-image: url(/img/bg-1.svg); background-size: cover"
	>
		<div class="shell ">
			<div class="page-top-bar">
				<div class="breadcrumbs">
					<ul>
						<li>
							<a href="/home">Übersicht > </a>
						</li>

						<li>
							<a href="/my-way">My Way ></a>
						</li>

						<li>
							<span>Mein Fortschritt</span>
						</li>
					</ul>
				</div>

				<div
					class="search"
					@click.prevent="showNotification($event, -110, 20)"
				>
					<div class="form-search">
						<form action="" @submit="" method="post">
							<div class="form__body">
								<div class="form__controls">
									<input
										type="text"
										class="search_field "
										value=""
										id="field-search"
										placeholder="Suchen"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="page_title page_title--underlined text-center">
				<h3>
					<span>Mein <br />Fortschritt</span>
				</h3>
			</div>

			<div class="row justify-content-center">
				<div class="col col-12 col-lg-10">
					<div class="tabs-default">
						<div class="tabs-nav-holder">
							<ul
								class="navigation-tabs navigation-tabs--scrollable navigation-tabs--scrollable-alt"
							>
								<li class="nav-item">
									<a
										class="nav-link"
										@click.prevent="setActive('tab1')"
										:class="{ active: isActive('tab1') }"
										href="#tab1"
										>Modul 1</a
									>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										@click.prevent="
											showNotification($event, -110, 20)
										"
										:class="{ active: isActive('tab2') }"
										href="#tab2"
										>Modul 2</a
									>
								</li>

								<li class="nav-item">
									<a
										class="nav-link"
										@click.prevent="
											showNotification($event, -110, 20)
										"
										:class="{ active: isActive('tab3') }"
										href="#tab3"
										>Modul 3</a
									>
								</li>

								<li class="nav-item">
									<a
										class="nav-link"
										@click.prevent="
											showNotification($event, -110, 20)
										"
										:class="{ active: isActive('tab4') }"
										href="#tab4"
										>Modul 4</a
									>
								</li>

								<li class="nav-item">
									<a
										class="nav-link"
										@click.prevent="
											showNotification($event, -110, 20)
										"
										:class="{ active: isActive('tab5') }"
										href="#tab5"
										>Modul 5</a
									>
								</li>

								<li class="nav-item">
									<a
										class="nav-link"
										@click.prevent="
											showNotification($event, -110, 20)
										"
										:class="{ active: isActive('tab6') }"
										href="#tab6"
										>Modul 6</a
									>
								</li>

								<li class="nav-item">
									<a
										class="nav-link"
										@click.prevent="
											showNotification($event, -110, 20)
										"
										:class="{ active: isActive('tab7') }"
										href="#tab7"
										>WarmUps</a
									>
								</li>
							</ul>
						</div>

						<div
							class="tab-content tab-content--spaced tab-content--transparent py-3"
							id="myTabContent"
						>
							<div
								class="tab-pane fade"
								:class="{
									'active show': isActive('tab1')
								}"
								id="tab1"
							>
								<div>
									<div class="table table-label">
										<table>
											<tr>
												<th class="cell-size1">
													Kapitel
												</th>
												<th
													class="cell-size2"
													v-if="
														(isAccordion1Expanded ||
															isAccordion2Expanded ||
															isAccordion3Expanded) &&
															$mq !== 'mobile'
													"
												>
													Wiederholen
												</th>
												<th
													class="cell-size3"
													v-if="
														(isAccordion1Expanded ||
															isAccordion2Expanded ||
															isAccordion3Expanded) &&
															$mq !== 'mobile'
													"
												>
													Favorit
												</th>
												<th
													class="cell-size4"
													v-if="
														(isAccordion1Expanded ||
															isAccordion2Expanded ||
															isAccordion3Expanded) &&
															$mq !== 'mobile'
													"
												>
													Experte
												</th>
											</tr>
										</table>
									</div>
								</div>

								<div class="accordion">
									<div
										:class="[
											'accordion__toggle',
											{
												'accordion__toggle--open': this
													.isAccordion1Expanded
											}
										]"
										@click.prevent="
											showNotification($event, -110, 20)
										"
									>
										<i class="lock-icon">
											<svg
												id="Group_4690"
												data-name="Group 4690"
												xmlns="http://www.w3.org/2000/svg"
												width="13.661"
												height="19.427"
												viewBox="0 0 13.661 19.427"
											>
												<path
													id="Path_10633"
													data-name="Path 10633"
													d="M5700.249-1248.641v3.282h-1.536v-3.282a3.227,3.227,0,0,0-3.219-3.223,3.228,3.228,0,0,0-3.223,3.223v3.282h-1.536v-3.282a4.764,4.764,0,0,1,4.759-4.759A4.762,4.762,0,0,1,5700.249-1248.641Z"
													transform="translate(-5688.661 1253.4)"
													fill="#fff"
												/>
												<path
													id="Path_10634"
													data-name="Path 10634"
													d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
													transform="translate(-5683.755 1234.371)"
													fill="#fff"
												/>
											</svg>
										</i>
										<h5>Basis Umfrage</h5>

										<i class="arrow">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="27.121"
												height="14.31"
												viewBox="0 0 27.121 14.31"
											>
												<path
													id="Path_11611"
													data-name="Path 11611"
													d="M7609.615,1069l12.5,12.5,12.5-12.5"
													transform="translate(-7608.554 -1067.939)"
													fill="none"
													stroke="#efffff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="1.5"
												/>
											</svg>
										</i>
									</div>

									<div class="accordion__body">
										<VueSlideToggle
											:open="this.isAccordion1Expanded"
											tag="section"
											:duration="500"
										>
										</VueSlideToggle>
									</div>
								</div>

								<div class="accordion">
									<div
										:class="[
											'accordion__toggle',
											{
												'accordion__toggle--open': this
													.isAccordion2Expanded
											}
										]"
										@click="expandAccordion2"
									>
										<i class="lock-icon">
											<svg
												id="Group_341"
												data-name="Group 341"
												xmlns="http://www.w3.org/2000/svg"
												width="19.587"
												height="19.427"
												viewBox="0 0 19.587 19.427"
											>
												<path
													id="Path_10633"
													data-name="Path 10633"
													d="M5690.734-1248.641v3.282h1.536v-3.282a3.227,3.227,0,0,1,3.219-3.223,3.228,3.228,0,0,1,3.223,3.223v3.282h1.536v-3.282a4.764,4.764,0,0,0-4.759-4.759A4.762,4.762,0,0,0,5690.734-1248.641Z"
													transform="translate(-5680.661 1253.4)"
													fill="#fff"
												/>
												<path
													id="Path_10634"
													data-name="Path 10634"
													d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
													transform="translate(-5683.755 1234.371)"
													fill="#fff"
												/>
											</svg>
										</i>
										<h5>Bedeutung mentaler Stärke</h5>

										<i class="arrow">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="27.121"
												height="14.31"
												viewBox="0 0 27.121 14.31"
											>
												<path
													id="Path_11611"
													data-name="Path 11611"
													d="M7609.615,1069l12.5,12.5,12.5-12.5"
													transform="translate(-7608.554 -1067.939)"
													fill="none"
													stroke="#efffff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="1.5"
												/>
											</svg>
										</i>
									</div>

									<div class="accordion__body">
										<VueSlideToggle
											:open="this.isAccordion2Expanded"
											tag="section"
											:duration="500"
										>
											<div class="table table--dark">
												<table>
													<tr>
														<td
															class="cell-size1 cell-with-icon"
														>
															<div>
																<i
																	class="lock-icon"
																>
																	<svg
																		id="Group_341"
																		data-name="Group 341"
																		xmlns="http://www.w3.org/2000/svg"
																		width="19.587"
																		height="19.427"
																		viewBox="0 0 19.587 19.427"
																	>
																		<path
																			id="Path_10633"
																			data-name="Path 10633"
																			d="M5690.734-1248.641v3.282h1.536v-3.282a3.227,3.227,0,0,1,3.219-3.223,3.228,3.228,0,0,1,3.223,3.223v3.282h1.536v-3.282a4.764,4.764,0,0,0-4.759-4.759A4.762,4.762,0,0,0,5690.734-1248.641Z"
																			transform="translate(-5680.661 1253.4)"
																			fill="#fff"
																		/>
																		<path
																			id="Path_10634"
																			data-name="Path 10634"
																			d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
																			transform="translate(-5683.755 1234.371)"
																			fill="#fff"
																		/>
																	</svg>
																</i>

																Intro zu
																mentaler Stärke
															</div>
														</td>

														<td class="cell-size2">
															<i
																@click.prevent="
																	showNotification(
																		$event,
																		20,
																		20
																	)
																"
															>
																<svg
																	width="20px"
																	height="22px"
																	viewBox="0 0 20 22"
																	version="1.1"
																	xmlns="http://www.w3.org/2000/svg"
																	xmlns:xlink="http://www.w3.org/1999/xlink"
																>
																	<g
																		id="Page-1"
																		stroke="none"
																		stroke-width="1"
																		fill="none"
																		fill-rule="evenodd"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	>
																		<g
																			id="SIB005-Prototyping-16_43"
																			transform="translate(1.000000, 1.000000)"
																			stroke="#FFFFFF"
																			stroke-width="2"
																		>
																			<path
																				d="M6.7248,18.3744 C2.49976786,17.504434 -0.38188196,13.5787554 0.0543273076,9.28719867 C0.490536575,4.99564196 4.10313181,1.73006178 8.4168,1.728"
																				id="Path"
																			></path>
																			<path
																				d="M11.0232,1.944 C15.1877719,2.90653169 17.9678382,6.83993309 17.4853045,11.0869659 C17.0027709,15.3339987 13.4111552,18.543471 9.1368,18.5472"
																				id="Path"
																			></path>
																			<polyline
																				id="Path"
																				points="12.2112 4.2768 10.5624 1.836 13.0032 0.1872"
																			></polyline>
																			<polyline
																				id="Path"
																				points="5.1336 15.9408 6.7824 18.3816 4.3416 20.0304"
																			></polyline>
																		</g>
																	</g>
																</svg>
															</i>
														</td>

														<td class="cell-size3">
															<i
																@click.prevent="
																	showNotification(
																		$event,
																		20,
																		20
																	)
																"
															>
																<svg
																	width="27px"
																	height="24px"
																	viewBox="0 0 27 24"
																	version="1.1"
																	xmlns="http://www.w3.org/2000/svg"
																	xmlns:xlink="http://www.w3.org/1999/xlink"
																>
																	<g
																		id="Page-1"
																		stroke="none"
																		stroke-width="1"
																		fill="none"
																		fill-rule="evenodd"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	>
																		<g
																			id="SIB005-Prototyping-16_43-copy"
																			transform="translate(1.000000, 1.000000)"
																			stroke="#FFFFFF"
																			stroke-width="1.5"
																		>
																			<path
																				d="M12.19,18.94 C0.19,12.39 8.87,4.25 12.19,9.74 C15.51,4.25 24.19,12.39 12.19,18.94 Z"
																				id="Path"
																			></path>
																			<path
																				d="M18.64,19.35 C19.4537603,19.9637587 20.2011526,20.660878 20.87,21.43"
																				id="Path"
																			></path>
																			<path
																				d="M20.91,4.08 C20.0777971,4.80891413 19.2834258,5.57992157 18.53,6.39"
																				id="Path"
																			></path>
																			<path
																				d="M12.15,0.66 C12.2766794,1.69152356 12.3134845,2.73210409 12.26,3.77"
																				id="Path"
																			></path>
																			<path
																				d="M5.59,6.51 L3.48,4.32"
																				id="Path"
																			></path>
																			<path
																				d="M3.32,13.07 L0.06,13"
																				id="Path"
																			></path>
																			<path
																				d="M5.72,19.54 C5.64,19.54 5.54,19.54 5.48,19.64 L4,21.25"
																				id="Path"
																			></path>
																			<path
																				d="M24.32,12.82 L21.32,12.75"
																				id="Path"
																			></path>
																		</g>
																	</g>
																</svg>
															</i>
														</td>

														<td class="cell-size4">
															<i
																@click.prevent="
																	showNotification(
																		$event,
																		20,
																		20
																	)
																"
															>
																<svg
																	width="24px"
																	height="16px"
																	viewBox="0 0 24 16"
																	version="1.1"
																	xmlns="http://www.w3.org/2000/svg"
																	xmlns:xlink="http://www.w3.org/1999/xlink"
																>
																	<g
																		id="Page-1"
																		stroke="none"
																		stroke-width="1"
																		fill="none"
																		fill-rule="evenodd"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	>
																		<g
																			id="SIB005-Prototyping-16_43-copy-3"
																			transform="translate(1.000000, 1.000000)"
																			stroke="#FFFFFF"
																			stroke-width="1.5"
																		>
																			<path
																				d="M1.44,4.47 L1.44,9.95"
																				id="Path"
																			></path>
																			<path
																				d="M18.7,6.29 L18.7,10.8 C18.7,10.8 16.68,14 11.33,14 C5.73,14 3.69,10.78 3.69,10.78 L3.69,6.29"
																				id="Path"
																			></path>
																			<polygon
																				id="Path"
																				points="11.19 8.89 0.24 4.47 11.19 0.06 22.14 4.47"
																			></polygon>
																		</g>
																	</g>
																</svg>
															</i>
														</td>
													</tr>

													<tr>
														<td
															class="cell-size1 cell-with-icon"
														>
															<div>
																<i
																	class="lock-icon"
																>
																	<svg
																		id="Group_341"
																		data-name="Group 341"
																		xmlns="http://www.w3.org/2000/svg"
																		width="19.587"
																		height="19.427"
																		viewBox="0 0 19.587 19.427"
																	>
																		<path
																			id="Path_10633"
																			data-name="Path 10633"
																			d="M5690.734-1248.641v3.282h1.536v-3.282a3.227,3.227,0,0,1,3.219-3.223,3.228,3.228,0,0,1,3.223,3.223v3.282h1.536v-3.282a4.764,4.764,0,0,0-4.759-4.759A4.762,4.762,0,0,0,5690.734-1248.641Z"
																			transform="translate(-5680.661 1253.4)"
																			fill="#fff"
																		/>
																		<path
																			id="Path_10634"
																			data-name="Path 10634"
																			d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
																			transform="translate(-5683.755 1234.371)"
																			fill="#fff"
																		/>
																	</svg>
																</i>

																WarmUp
															</div>
														</td>

														<td class="cell-size2">
															<i
																@click.prevent="
																	showNotification(
																		$event,
																		20,
																		20
																	)
																"
															>
																<svg
																	width="20px"
																	height="22px"
																	viewBox="0 0 20 22"
																	version="1.1"
																	xmlns="http://www.w3.org/2000/svg"
																	xmlns:xlink="http://www.w3.org/1999/xlink"
																>
																	<g
																		id="Page-1"
																		stroke="none"
																		stroke-width="1"
																		fill="none"
																		fill-rule="evenodd"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	>
																		<g
																			id="SIB005-Prototyping-16_43"
																			transform="translate(1.000000, 1.000000)"
																			stroke="#FFFFFF"
																			stroke-width="2"
																		>
																			<path
																				d="M6.7248,18.3744 C2.49976786,17.504434 -0.38188196,13.5787554 0.0543273076,9.28719867 C0.490536575,4.99564196 4.10313181,1.73006178 8.4168,1.728"
																				id="Path"
																			></path>
																			<path
																				d="M11.0232,1.944 C15.1877719,2.90653169 17.9678382,6.83993309 17.4853045,11.0869659 C17.0027709,15.3339987 13.4111552,18.543471 9.1368,18.5472"
																				id="Path"
																			></path>
																			<polyline
																				id="Path"
																				points="12.2112 4.2768 10.5624 1.836 13.0032 0.1872"
																			></polyline>
																			<polyline
																				id="Path"
																				points="5.1336 15.9408 6.7824 18.3816 4.3416 20.0304"
																			></polyline>
																		</g>
																	</g>
																</svg>
															</i>
														</td>

														<td class="cell-size3">
															<i
																@click.prevent="
																	showNotification(
																		$event,
																		20,
																		20
																	)
																"
															>
																<svg
																	width="27px"
																	height="24px"
																	viewBox="0 0 27 24"
																	version="1.1"
																	xmlns="http://www.w3.org/2000/svg"
																	xmlns:xlink="http://www.w3.org/1999/xlink"
																>
																	<g
																		id="Page-1"
																		stroke="none"
																		stroke-width="1"
																		fill="none"
																		fill-rule="evenodd"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	>
																		<g
																			id="SIB005-Prototyping-16_43-copy-2"
																			transform="translate(1.000000, 1.000000)"
																			fill="#FFFFFF"
																			stroke="#FFFFFF"
																			stroke-width="1.5"
																		>
																			<path
																				d="M12.19,18.94 C0.19,12.39 8.87,4.25 12.19,9.74 C15.51,4.25 24.19,12.39 12.19,18.94 Z"
																				id="Path"
																			></path>
																			<path
																				d="M18.64,19.35 C19.4537603,19.9637587 20.2011526,20.660878 20.87,21.43"
																				id="Path"
																			></path>
																			<path
																				d="M20.91,4.08 C20.0777971,4.80891413 19.2834258,5.57992157 18.53,6.39"
																				id="Path"
																			></path>
																			<path
																				d="M12.15,0.66 C12.2766794,1.69152356 12.3134845,2.73210409 12.26,3.77"
																				id="Path"
																			></path>
																			<path
																				d="M5.59,6.51 L3.48,4.32"
																				id="Path"
																			></path>
																			<path
																				d="M3.32,13.07 L0.06,13"
																				id="Path"
																			></path>
																			<path
																				d="M5.72,19.54 C5.64,19.54 5.54,19.54 5.48,19.64 L4,21.25"
																				id="Path"
																			></path>
																			<path
																				d="M24.32,12.82 L21.32,12.75"
																				id="Path"
																			></path>
																		</g>
																	</g>
																</svg>
															</i>
														</td>

														<td class="cell-size4">
															<i
																@click.prevent="
																	showNotification(
																		$event,
																		20,
																		20
																	)
																"
															>
																<svg
																	width="23px"
																	height="16px"
																	viewBox="0 0 23 16"
																	version="1.1"
																	xmlns="http://www.w3.org/2000/svg"
																	xmlns:xlink="http://www.w3.org/1999/xlink"
																>
																	<g
																		id="Page-1"
																		stroke="none"
																		stroke-width="1"
																		fill="none"
																		fill-rule="evenodd"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	>
																		<g
																			id="SIB005-Prototyping-16_43-copy-4"
																			transform="translate(0.000000, 1.000000)"
																			fill="#FFFFFF"
																		>
																			<path
																				d="M1.44,4.47 L1.44,9.95"
																				id="Path"
																				stroke="#FFFFFF"
																				stroke-width="1.5"
																			></path>
																			<path
																				d="M18.7,6.29 L18.7,10.8 C18.7,10.8 16.68,14 11.33,14 C5.73,14 3.69,10.78 3.69,10.78 L3.69,6.29"
																				id="Path"
																				stroke="#004850"
																				stroke-width="0.75"
																			></path>
																			<polygon
																				id="Path"
																				stroke="#004850"
																				stroke-width="0.75"
																				points="11.19 8.89 0.24 4.47 11.19 0.06 22.14 4.47"
																			></polygon>
																		</g>
																	</g>
																</svg>
															</i>
														</td>
													</tr>
												</table>
											</div>
										</VueSlideToggle>
									</div>
								</div>

								<div class="accordion">
									<div
										class="accordion__toggle"
										@click.prevent="
											showNotification($event, -110, 20)
										"
									>
										<i class="lock-icon">
											<svg
												id="Group_4690"
												data-name="Group 4690"
												xmlns="http://www.w3.org/2000/svg"
												width="13.661"
												height="19.427"
												viewBox="0 0 13.661 19.427"
											>
												<path
													id="Path_10633"
													data-name="Path 10633"
													d="M5700.249-1248.641v3.282h-1.536v-3.282a3.227,3.227,0,0,0-3.219-3.223,3.228,3.228,0,0,0-3.223,3.223v3.282h-1.536v-3.282a4.764,4.764,0,0,1,4.759-4.759A4.762,4.762,0,0,1,5700.249-1248.641Z"
													transform="translate(-5688.661 1253.4)"
													fill="#fff"
												/>
												<path
													id="Path_10634"
													data-name="Path 10634"
													d="M5696.21-1226.33h-11.25a1.208,1.208,0,0,0-1.206,1.209v8.968a1.208,1.208,0,0,0,1.206,1.209h11.25a1.208,1.208,0,0,0,1.206-1.209v-8.968A1.208,1.208,0,0,0,5696.21-1226.33Zm-4.812,6.068v1.815a.812.812,0,0,1-.811.811.813.813,0,0,1-.814-.811v-1.815a1.625,1.625,0,0,1-.811-1.405,1.625,1.625,0,0,1,1.625-1.622,1.623,1.623,0,0,1,1.622,1.622A1.624,1.624,0,0,1,5691.4-1220.261Z"
													transform="translate(-5683.755 1234.371)"
													fill="#fff"
												/>
											</svg>
										</i>
										<h5>Einstieg in Sibando</h5>

										<i class="arrow">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="27.121"
												height="14.31"
												viewBox="0 0 27.121 14.31"
											>
												<path
													id="Path_11611"
													data-name="Path 11611"
													d="M7609.615,1069l12.5,12.5,12.5-12.5"
													transform="translate(-7608.554 -1067.939)"
													fill="none"
													stroke="#efffff"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="1.5"
												/>
											</svg>
										</i>
									</div>

									<div class="accordion__body">
										<VueSlideToggle
											:open="this.isAccordion3Expanded"
											tag="section"
											:duration="500"
										>
										</VueSlideToggle>
									</div>
								</div>
							</div>

							<div
								class="tab-pane fade"
								:class="{ 'active show': isActive('tab2') }"
								id="tab2"
							></div>

							<div
								class="tab-pane fade"
								:class="{ 'active show': isActive('tab3') }"
								id="tab3"
							></div>

							<div
								class="tab-pane fade"
								:class="{ 'active show': isActive('tab4') }"
								id="tab4"
							></div>

							<div
								class="tab-pane fade"
								:class="{ 'active show': isActive('tab5') }"
								id="tab5"
							></div>

							<div
								class="tab-pane fade"
								:class="{ 'active show': isActive('tab6') }"
								id="tab6"
							></div>

							<div
								class="tab-pane fade"
								:class="{ 'active show': isActive('tab7') }"
								id="tab7"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<NotificationBox
			:event="this.event"
			:showNotification="this.isNotificationVisible"
			:offsetLeft="this.offsetLeft"
			:offsetTop="this.offsetTop"
			@onClose="this.onNotificationClose"
		/>
	</div>
</template>

<script>
import NotificationBox from '@/components/NotificationBox.vue';

export default {
	name: 'MyWayInner',
	components: {
		NotificationBox
	},
	data() {
		return {
			activeItem: 'tab1',
			isAccordion1Expanded: false,
			isAccordion2Expanded: false,
			isAccordion3Expanded: false,
			isAccordion4Expanded: false,

			isNotificationVisible: false,
			offsetLeft: 0,
			offsetTop: 0,
			event: {}
		};
	},
	methods: {
		isActive(menuItem) {
			return this.activeItem === menuItem;
		},
		setActive(menuItem) {
			this.activeItem = menuItem;
		},
		expandAccordion1() {
			this.isAccordion1Expanded = !this.isAccordion1Expanded;
		},
		expandAccordion2() {
			this.isAccordion2Expanded = !this.isAccordion2Expanded;
		},
		expandAccordion3() {
			this.isAccordion3Expanded = !this.isAccordion3Expanded;
		},
		expandAccordion4() {
			this.isAccordion4Expanded = !this.isAccordion4Expanded;
		},

		showNotification(event, x, y) {
			this.isNotificationVisible = true;
			this.event = event;
			this.offsetLeft = x;
			this.offsetTop = y;
		},
		onNotificationClose() {
			this.isNotificationVisible = false;
		}
	},
	mounted: function() {
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
