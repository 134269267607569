<template>
	<div class="loader-holder">
		<lottie-animation
			class="loader-icon"
			path="../preloader.json"
			:width="80"
			:height="80"
		/>
	</div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs
export default {
	name: 'Loader',
	components: {
		LottieAnimation
	},

	data() {
		return {};
	}
};
</script>

<style lang="scss">
.loader-icon {
	path {
		fill: rgba(234, 244, 244, 0.8);
	}
}

.white-loader {
	path {
		fill: #fff;
	}
}
</style>
