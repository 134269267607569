<template>
	<div
		class="home main main--with-bg main--exercise"
		style="background-image: url(/img/bg-2.svg); background-size: cover"
	>
		<div
			class="shell shell--small d-flex flex-column justify-content-center"
		>
			<div class="cell-center">
				<Loader v-show="this.isLoading" />

				<div v-show="!this.isLoading" class="shell-inner">
					<div
						class="text-box text-box-less-space-bottom text-box-less-space-top"
					>
						<h5 class="text-center">Intro zu mentaler Stärke</h5>

						<div class="text-box__inner">
							<i>
								<img
									src="/img/ico-feature1.svg"
									alt=""
									width="57"
									height="57"
								/>
							</i>

							<p>
								Die Reise zu Deiner mentalen Stärke beginnt
								jetzt. Freue Dich auf spannende Trainings, die
								Dich Dir näher bringen.
							</p>
						</div>
					</div>

					<div class="video-container">
						<youtube
							:video-id="videoId"
							ref="youtube"
							@playing="playing"
						></youtube>
					</div>
				</div>
			</div>

			<div class="bottom-actions bottom-actions-with-shell">
				<div class="shell shell--alt">
					<btn-back></btn-back>
					<a
						href="/exercise1-2"
						class="btn btn--orange btn--text-small "
						>Weiter</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NotificationBox from '@/components/NotificationBox.vue';
import BtnBack from '@/components/BtnBack.vue';
import Loader from '@/components/Loader.vue';
export default {
	name: 'Exercise1-1',
	components: { NotificationBox, BtnBack, Loader },
	data() {
		return {
			videoId: 'THdNLjrFMuQ',
			isNotificationVisible: false,
			positionLeft: '',
			positionTop: '',
			isLoading: false
		};
	},
	methods: {
		playVideo() {
			this.player.playVideo();
		},
		playing() {
			console.log('\o/ we are watching!!!');
		},
		showNotification(event, x, y) {
			this.isNotificationVisible = true;
			this.positionLeft = event.pageX + x;
			this.positionTop = event.pageY + y;

			console.log(this.positionLeft);
		},
		closeNotification() {
			this.isNotificationVisible = false;
			this.positionLeft = 0;
			this.positionTop = 0;
		},
		loading() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 2000);
		}
	},
	computed: {
		player() {
			return this.$refs.youtube.player;
		}
	},
	mounted: function() {
		this.loading();
		const expireTime = localStorage.getItem('loginExpireTime');
		const timeNow = Date.now();

		if (localStorage.getItem('authUser') === null || expireTime < timeNow) {
			this.$router.push('/login');
		}
	}
};
</script>
